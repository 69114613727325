import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";

type NewsListFilterStateType = Parameters<
  typeof fetchApiClient.news.getListNewsWithPagination
>["0"];

export const newsQueries = {
  list: (params: NewsListFilterStateType) => {
    return queryOptions({
      queryKey: ["news-list", params],
      queryFn: async () => {
        try {
          const { data } =
            await fetchApiClient.news.getListNewsWithPagination(params);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    });
  },
  details: (id: number) => {
    return queryOptions({
      queryKey: ["news-detail", Number(id)],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.news.getDetailNew(id);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
  allCategories: () => {
    return queryOptions({
      queryKey: ["news-categories-all"],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.news.getAllCategories();
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
    });
  },
  categories: (props: PaginateFilterState) => {
    return queryOptions({
      queryKey: ["news-categories", props],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.news.getListCategories(props);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    });
  },
  categoryDetails: (id: number) => {
    return queryOptions({
      queryKey: ["news-category-details", Number(id)],
      queryFn: async () => {
        try {
          const { data } = await fetchApiClient.news.getACategoryForNew(id);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    });
  },
};

const newsFilterAtom = atomWithDebounce<NewsListFilterStateType>({
  page: 1,
  search: "",
});

export const categoryFilterAtom = atomWithDebounce<PaginateFilterState>({
  page: 1,
  search: "",
});

export const newsAtoms = {
  filter: newsFilterAtom,
  categoriesFilter: categoryFilterAtom,
  categories: atomWithSuspenseQuery((get) =>
    newsQueries.categories(get(categoryFilterAtom.debouncedValueAtom)),
  ),
  list: atomWithSuspenseQuery((get) =>
    newsQueries.list(get(newsFilterAtom.debouncedValueAtom)),
  ),
  allCategories: atomWithSuspenseQuery(() => newsQueries.allCategories()),
  details: atomFamily((id: number) =>
    atomWithSuspenseQuery(() => newsQueries.details(id)),
  ),
  categoryDetail: atomFamily((id: number) =>
    atomWithSuspenseQuery(() => newsQueries.categoryDetails(id)),
  ),
};
