import { ParseRoute, useNavigate, useRouter } from "@tanstack/react-router";

import { routeTree } from "@/routeTree.gen";

export const useOnRowTable = <T extends { id?: string | number }>({
  to,
}: {
  to: ParseRoute<typeof routeTree>["fullPath"];
}) => {
  const navigate = useNavigate();
  const router = useRouter();

  return (record: T) => {
    const routeParams = {
      to,
      params: { id: record.id?.toString() ?? "" },
    };

    return {
      className: "cursor-pointer",
      onPointerEnter: () => {
        void router.preloadRoute(routeParams);
      },
      onClick: () => {
        if (!window.getSelection()?.toString()) {
          void navigate(routeParams);
        }
      },
    };
  };
};
