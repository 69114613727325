import { queryOptions } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { fetchApiClient } from "@/lib/utils/fetchUtils";

import { authAtom } from "./oidc";

export const UserRole = {
  ADMIN: "admin",
  USER: "user",
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

export const authUserAtom = atomWithSuspenseQuery((get) => {
  const auth = get(authAtom);
  return {
    queryKey: ["authUser"],
    queryFn: async () => {
      const user = await auth.getUser();
      return user;
    },
  };
});

export const userQueries = {
  all: () =>
    queryOptions({
      queryKey: ["users", "all"],
      queryFn: async () => {
        const { data } = await fetchApiClient.accounts.getAllUsers();
        return data;
      },
    }),
  colleges: () =>
    queryOptions({
      queryKey: ["colleges", "all"],
      queryFn: async () => {
        const { data } = await fetchApiClient.accounts.getAllAcademicColleges();
        return data;
      },
    }),
  cohorts: () =>
    queryOptions({
      queryKey: ["cohorts", "all"],
      queryFn: async () => {
        const { data } = await fetchApiClient.accounts.getAllAcademicCohorts();
        return data;
      },
    }),
  userInfo: () =>
    queryOptions({
      queryKey: ["me"],
      queryFn: async () => {
        const res = await fetchApiClient.accounts.retrieveUsersProfile();
        return res.data;
      },
      staleTime: 1000 * 60 * 5,
    }),
};

export const userAtoms = {
  all: atomWithSuspenseQuery(() => userQueries.all()),
  colleges: atomWithSuspenseQuery(() => userQueries.colleges()),
  cohorts: atomWithSuspenseQuery(() => userQueries.cohorts()),
  userInfo: atomWithSuspenseQuery(() => userQueries.userInfo()),
};

export const useUserInfo = () => {
  return useAtomValue(userAtoms.userInfo);
};

export const useAllUsers = () => {
  return useAtomValue(userAtoms.all);
};

export const useAllColleges = () => {
  return useAtomValue(userAtoms.colleges);
};

export const useAllCohorts = () => {
  return useAtomValue(userAtoms.cohorts);
};
