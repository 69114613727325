/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Group {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /** @uniqueItems true */
  permissions?: number[];
}

export interface CreateGroupBody {
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface RegisterBody {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Password
   * @minLength 8
   */
  password: string;
  /**
   * First name
   * @minLength 1
   */
  first_name?: string;
  /**
   * Last name
   * @minLength 1
   */
  last_name?: string;
}

export interface User {
  /** ID */
  id?: number;
  /**
   * Last login
   * @format date-time
   */
  last_login?: string | null;
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean;
  /**
   * Username
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @minLength 1
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean;
  /**
   * Active
   * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
   */
  is_active?: boolean;
  /**
   * Date joined
   * @format date-time
   */
  date_joined?: string;
  /**
   * The groups this user belongs to. A user will get all permissions granted to each of their groups.
   * @uniqueItems true
   */
  groups?: number[];
  /**
   * Specific permissions for this user.
   * @uniqueItems true
   */
  user_permissions?: number[];
}

export interface UserSetting {
  /** ID */
  id?: number;
  /** Locale */
  locale?: "VIETNAMESE" | "ENGLISH";
  /**
   * Passcode
   * @maxLength 10
   */
  passcode?: string | null;
}

export interface PasswordLoginBody {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Password
   * @minLength 8
   */
  password: string;
}

export interface PasswordLoginResponse {
  /**
   * Access token
   * @minLength 1
   */
  access_token: string;
  /**
   * Refresh token
   * @minLength 1
   */
  refresh_token: string;
}

export interface RefreshTokenBody {
  /**
   * Refresh token
   * @minLength 1
   */
  refresh_token: string;
}

export interface RefreshTokenResponse {
  /**
   * Access token
   * @minLength 1
   */
  access_token: string;
  /**
   * Refresh token
   * @minLength 1
   */
  refresh_token: string;
}

export interface PersonalProfile {
  /** ID */
  id?: number;
  /**
   * Photo
   * @format base64
   * @maxLength 2000
   */
  photo?: string | null;
  /**
   * First name
   * @maxLength 255
   */
  first_name?: string | null;
  /**
   * Last name
   * @maxLength 255
   */
  last_name?: string | null;
  /**
   * Student number
   * @maxLength 255
   */
  student_number?: string | null;
  /**
   * University email
   * @format email
   * @maxLength 255
   */
  university_email?: string | null;
  /**
   * Personal email
   * @format email
   * @maxLength 255
   */
  personal_email?: string | null;
  /**
   * Phone number
   * @maxLength 255
   */
  phone_number?: string | null;
  /**
   * Id card number
   * @maxLength 255
   */
  id_card_number?: string | null;
  /**
   * Nationality
   * @maxLength 255
   */
  nationality?: string | null;
  /**
   * Address
   * @maxLength 255
   */
  address?: string | null;
  /** User */
  user: number;
  /** @uniqueItems true */
  managers?: number[];
}

export interface StudentProject {
  /** ID */
  id?: string;
  /** Project type */
  type:
    | "Community Service"
    | "Internship/Work Experience"
    | "Research Experience"
    | "Entrepreneurial Experience"
    | "Global Experience"
    | "Voluntary Work"
    | "Club and Association";
  /**
   * Name
   * @maxLength 255
   */
  name?: string | null;
  /** Summary */
  summary?: string | null;
  /**
   * Status
   * @maxLength 255
   */
  status?: string | null;
  /**
   * Position
   * @maxLength 255
   */
  position?: string | null;
  /**
   * Supervisor name
   * @maxLength 255
   */
  supervisor_name?: string | null;
  /**
   * Supervisor position
   * @maxLength 255
   */
  supervisor_position?: string | null;
  /**
   * Supervisor email
   * @format email
   * @maxLength 255
   */
  supervisor_email?: string | null;
  /**
   * Supervisor mobile
   * @maxLength 255
   */
  supervisor_mobile?: string | null;
  /** Supervisor comment */
  supervisor_comment?: string | null;
  /** Excel score */
  excel_score?:
    | "Empathy"
    | "Exceptional Capability"
    | "Creativity"
    | "Entrepreneurial Mindset"
    | "Leadership Spirit"
    | null;
  /**
   * Industry name
   * @maxLength 255
   */
  industry_name?: string | null;
  /**
   * Start date
   * @format date
   */
  start_date?: string | null;
  /**
   * End date
   * @format date
   */
  end_date?: string | null;
  /**
   * Verification status
   * @maxLength 255
   */
  verification_status?: string | null;
  /** Csl hour required */
  csl_hour_required?: number | null;
  /** Csl hour completed */
  csl_hour_completed?: number | null;
  /** Self reflection */
  self_reflection?: string | null;
  /**
   * Semester
   * @maxLength 255
   */
  semester?: string | null;
  /**
   * Host university
   * @maxLength 255
   */
  host_university?: string | null;
  /**
   * Host country
   * @maxLength 255
   */
  host_country?: string | null;
  /**
   * Enrolled program
   * @maxLength 255
   */
  enrolled_program?: string | null;
  /**
   * Award
   * @maxLength 255
   */
  award?: string | null;
  /** Total funding */
  total_funding?: number | null;
  /**
   * Work experience attachments
   * @format uri
   */
  work_experience_attachments?: string | null;
  /** Research questions */
  research_questions?: string | null;
  /** Highlighted achivements */
  highlighted_achivements?: string | null;
  /**
   * Endorsed user
   * @maxLength 255
   */
  endorsed_user?: string | null;
}

export interface Semester {
  /**
   * Fall semester name
   * @minLength 1
   */
  fall_semester_name?: string | null;
  /** Fall credit earned */
  fall_credit_earned?: number | null;
  /** Fall curriculum gpa */
  fall_curriculum_gpa?: number | null;
  /** Fall cumulative gpa */
  fall_cumulative_gpa?: number | null;
  /**
   * Fall sap status
   * @minLength 1
   */
  fall_sap_status?: string | null;
  /**
   * Spring semester name
   * @minLength 1
   */
  spring_semester_name?: string | null;
  /** Spring credit earned */
  spring_credit_earned?: number | null;
  /** Spring curriculum gpa */
  spring_curriculum_gpa?: number | null;
  /** Spring cumulative gpa */
  spring_cumulative_gpa?: number | null;
  /**
   * Spring sap status
   * @minLength 1
   */
  spring_sap_status?: string | null;
  /**
   * Spring semester name
   * @minLength 1
   */
  summer_semester_name?: string | null;
  /** Spring credit earned */
  summer_credit_earned?: number | null;
  /** Spring curriculum gpa */
  summer_curriculum_gpa?: number | null;
  /** Spring cumulative gpa */
  summer_cumulative_gpa?: number | null;
  /**
   * Spring sap status
   * @minLength 1
   */
  summer_sap_status?: string | null;
}

export interface Honor {
  /** ID */
  id?: number;
  /**
   * Award type
   * @maxLength 255
   */
  award_type?: string | null;
  /**
   * Program
   * @maxLength 255
   */
  program?: string | null;
  /**
   * Term
   * @maxLength 255
   */
  term?: string | null;
  /**
   * College
   * @maxLength 255
   */
  college?: string | null;
}

export interface SelfAssignments {
  /** ID */
  id?: string;
  /** Assessment type */
  assessment_type: "IDP" | "Self-Nomination";
  /**
   * Idp id
   * @maxLength 255
   */
  idp_id?: string | null;
  /**
   * Status
   * @maxLength 255
   */
  status?: string | null;
  /**
   * Detail link
   * @format uri
   * @maxLength 255
   */
  detail_link?: string | null;
  /**
   * Name of award
   * @maxLength 255
   */
  name?: string | null;
  /**
   * Verification status
   * @maxLength 255
   */
  verification_status?: string | null;
  term?: string;
}

export interface CodeOfConduct {
  /** ID */
  id?: string;
  /**
   * Date of violation
   * @format date
   */
  date_of_violation?: string | null;
  /** Description */
  description?: string | null;
  /**
   * Type of violation
   * @maxLength 255
   */
  type_of_violation?: string | null;
  /**
   * Level of violation
   * @maxLength 255
   */
  level_of_violation?: string | null;
}

export interface UserInfoResponse {
  user: User & PersonalProfile;
  personal_profile: PersonalProfile;
}

export interface UserBasicInfo {
  /** ID */
  id?: number;
  /** Username */
  username?: string;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
}

export interface UserAttendee {
  /** Full name */
  name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @format url */
  photo?: string;
}

export interface ExtraUserAttendee {
  /** Full name */
  name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @format url */
  photo?: string;
  available_status?: "FREE" | "BUSY" | "UNKNOWN";
}

export interface BenefitType {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * En name
   * @maxLength 50
   */
  en_name?: string | null;
  /**
   * Order
   * @min -2147483648
   * @max 2147483647
   */
  order?: number | null;
}

export interface BenefitList {
  /** ID */
  id?: number;
  type?: BenefitType;
  created_by?: UserBasicInfo;
  /**
   * Title
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * Summary
   * @maxLength 500
   */
  summary?: string | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Start date
   * @format date
   */
  start_date: string;
  /**
   * End date
   * @format date
   */
  end_date: string;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Eligibility
   * @maxLength 2000
   */
  eligibility?: string | null;
  /**
   * Eligibility
   * @maxLength 2000
   */
  en_eligibility?: string | null;
  /**
   * How to redeem
   * @maxLength 2000
   */
  how_to_redeem?: string | null;
  /**
   * How to redeem
   * @maxLength 2000
   */
  en_how_to_redeem?: string | null;
  content?: string | null;
  en_content?: string | null;
}

export interface Benefit {
  /** ID */
  id?: number;
  type?: BenefitType;
  created_by?: UserBasicInfo;
  /**
   * Title
   * @minLength 1
   * @maxLength 500
   */
  title: string;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * Content
   * @maxLength 50000
   */
  content?: string | null;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * Summary
   * @maxLength 500
   */
  summary?: string | null;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Start date
   * @format date
   */
  start_date: string;
  /**
   * End date
   * @format date
   */
  end_date: string;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * Eligibility
   * @maxLength 2000
   */
  eligibility?: string | null;
  /**
   * Eligibility
   * @maxLength 2000
   */
  en_eligibility?: string | null;
  /**
   * How to redeem
   * @maxLength 2000
   */
  how_to_redeem?: string | null;
  /**
   * How to redeem
   * @maxLength 2000
   */
  en_how_to_redeem?: string | null;
}

export interface AppConfig {
  /** ID */
  id?: number;
  /**
   * Code
   * @minLength 1
   * @maxLength 255
   */
  code: string;
  /**
   * Delay time between ratings
   * @format int32
   * @min 0
   * @max 2147483647
   * @default 3600
   */
  rating_delay_seconds?: number;
  /**
   * Api timeout
   * @min 0
   * @max 2147483647
   */
  api_timeout?: number;
  /**
   * Default new thumbnail
   * @format uri
   */
  default_new_thumbnail?: string | null;
  /**
   * Default category icon
   * @format uri
   */
  default_category_icon?: string | null;
  /**
   * Home header image
   * @format uri
   */
  home_header_image?: string | null;
  /** Emergency contact */
  emergency_contact?:
    | {
        name: string;
        phone: string;
      }[]
    | null;
  /**
   * Library service url
   * @format uri
   * @maxLength 2000
   */
  library_service_url?: string | null;
  /** @format url */
  campus_guideline_handbook_url?: string;
  /** @format url */
  internation_student_handbook_url?: string;
  /** @format url */
  wow_handbook_url?: string;
  /**
   * Student policy url
   * @format uri
   * @maxLength 2000
   */
  student_policy_url?: string | null;
  /**
   * Faculty policy url
   * @format uri
   * @maxLength 2000
   */
  faculty_policy_url?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * CDN URI
   * @format url
   */
  cdn?: string;
  limit_rating_event_requests?: number;
  group_event_rating_after_days?: number;
  /** @format url */
  campus_map_url?: string;
  /** @format url */
  student_success_url?: string;
  /** @format url */
  code_of_conduct_url?: string;
  /** @format url */
  course_registration_url?: string;
}

export interface CalendarRoom {
  /**
   * @format email
   * @example "sparrow@zen8labs.com"
   */
  email?: string;
  /** @example "Sparrow" */
  name?: string;
  status?: "FREE" | "BUSY" | "UNKNOWN";
  /** @format url */
  photo?: string;
  capacity?: number;
}

export interface OutlookEvent {
  /** Unique identifier for the event. */
  id?: string;
  /** Subject or title of the event. */
  title?: string;
  /**
   * Start time of the event.
   * @format date-time
   */
  start?: string;
  /**
   * End time of the event.
   * @format date-time
   */
  end?: string;
  /** Whether the event lasts for the entire day. */
  is_all_day?: boolean;
  location?: {
    /** Display name for the event location. */
    name?: string;
    /**
     * Online meeting url
     * @format url
     */
    link?: string;
  };
  status?: "ACCEPTED" | "DECLINED" | "TENTATIVE" | "NOT_RESPONDED";
  room_status?: "ACCEPTED" | "DECLINED" | "TENTATIVE" | "NOT_RESPONDED";
  description?: string;
  participants?: {
    /** Name of attendee */
    name?: string;
    /** @format email */
    email?: string;
    /** @format url */
    photo?: string;
    /** is organizer of this event */
    is_organizer?: boolean;
    status?: "ACCEPTED" | "DECLINED" | "TENTATIVE" | "NOT_RESPONDED";
  }[];
  is_repeat?: boolean;
  type?: "NORMAL" | "COUNSELLING" | "FITNESS";
  counselling_id?: string | null;
}

export interface OutlookEventBooking {
  /** Subject or title of the event. */
  title: string;
  /**
   * Whether the event lasts for the entire day.
   * @default false
   */
  is_all_day: boolean;
  /**
   * Start time of the event.
   * @format date-time
   */
  start: string;
  /**
   * End time of the event
   * @format date-time
   */
  end: string;
  location?: {
    /** Display name for the event location. */
    name?: string;
    /**
     * Email of room
     * @format email
     */
    email?: string;
    /** Whether the meeting is an online meeting */
    is_online?: boolean;
  };
  repeat_type?: "NONE" | "DAILY" | "WEEKDAY" | "WEEKLY" | "MONTHLY" | "YEARLY";
  /**
   * End date of the repeat pattern.
   * @format date
   */
  end_repeat?: string;
  attendees?: string[];
  description?: string;
  type?: "NORMAL" | "COUNSELLING" | "FITNESS";
}

export interface OutlookEventUpdate {
  /** update this event, update following event, update all series. */
  action_type: "SINGLE" | "ALL_SERIES";
  /** Subject or title of the event. */
  title: string;
  /**
   * Whether the event lasts for the entire day.
   * @default false
   */
  is_all_day: boolean;
  /**
   * Start time of the event.
   * @format date-time
   */
  start: string;
  /**
   * End time of the event
   * @format date-time
   */
  end: string;
  location?: {
    /** Whether the meeting is an online meeting */
    is_online?: boolean;
    /** Display name for the event location. */
    name?: string;
    /**
     * Email of room
     * @format email
     */
    email?: string;
  };
  attendees?: string[];
  description?: string;
}

export interface OutlookEventCancel {
  /** update this event, update following event, update all series. */
  action_type: "SINGLE" | "ALL_SERIES";
  /** Cancel messages */
  comment?: string;
}

export interface OutlookEventResponse {
  /** Cancel messages */
  comment?: string;
  /** Is send notification to organizer */
  send_response?: boolean;
}

export interface Speaker {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Email
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * Phone number
   * @maxLength 15
   */
  phone_number?: string | null;
  /**
   * Job title
   * @maxLength 200
   */
  job_title?: string | null;
  /**
   * Job title
   * @maxLength 200
   */
  en_job_title?: string | null;
  /**
   * Company
   * @maxLength 200
   */
  company?: string | null;
  /**
   * Bio
   * @maxLength 2000
   */
  bio?: string | null;
  /**
   * Photo
   * @format uri
   */
  photo?: string | null;
}

export interface EventType {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * En name
   * @maxLength 50
   */
  en_name?: string | null;
  /**
   * Order
   * @min -2147483648
   * @max 2147483647
   */
  order?: number | null;
}

export interface EventAgenda {
  /** Start time */
  start_time: string;
  /** End time */
  end_time: string;
  /**
   * Activity
   * @minLength 1
   */
  activity: string;
  /**
   * En activity
   * @minLength 1
   */
  en_activity?: string;
  /**
   * Detail
   * @minLength 1
   */
  detail?: string;
  /**
   * En detail
   * @minLength 1
   */
  en_detail?: string;
}

export interface EventList {
  /** ID */
  id?: number;
  speakers?: Speaker[];
  event_types?: EventType[];
  agendas?: EventAgenda[];
  /** Is registered */
  is_registered?: boolean;
  created_by?: UserBasicInfo;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary: string;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** Is highlight */
  is_highlight?: boolean;
  /** Status */
  status?: "UPCOMING" | "ONGOING" | "COMPLETED" | "CANCELLED";
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /** Location */
  location?: string | null;
  /** Lat */
  lat?: number | null;
  /** Long */
  long?: number | null;
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** Contact */
  contact?: string | null;
  /**
   * N likes
   * @min 0
   * @max 2147483647
   */
  n_likes?: number;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /** @uniqueItems true */
  likes?: number[];
}

export interface EventReadInfo {
  /** ID */
  id?: number;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
}

export interface EventAdd {
  /** ID */
  id?: number;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * Content
   * @maxLength 10000
   */
  content?: string | null;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string;
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary?: string;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /** Published */
  published?: boolean;
  /** Created by */
  created_by: number;
  /** Is highlight */
  is_highlight?: boolean;
  /** Status */
  status?: "ACTIVE" | "CANCELLED";
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  /** Location */
  location?: string | null;
  /** Lat */
  lat?: number | null;
  /** Long */
  long?: number | null;
  /** @uniqueItems true */
  speakers?: number[];
  /** @uniqueItems true */
  event_types?: number[];
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** Contact */
  contact?: string | null;
  /** Agendas */
  agendas?: EventAgenda[];
  /** Read only */
  read_only?: boolean;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * En external id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
}

export interface Event {
  /** ID */
  id?: number;
  speakers?: Speaker[];
  event_types?: EventType[];
  agendas?: EventAgenda[];
  /** Is registered */
  is_registered?: boolean;
  /** Is liked */
  is_liked?: boolean;
  created_by?: UserBasicInfo;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * Content
   * @maxLength 10000
   */
  content?: string | null;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary: string;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** Is highlight */
  is_highlight?: boolean;
  /** Status */
  status?: "UPCOMING" | "ONGOING" | "COMPLETED" | "CANCELLED";
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  /** Location */
  location?: string | null;
  /** Lat */
  lat?: number | null;
  /** Long */
  long?: number | null;
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** Contact */
  contact?: string | null;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * En external id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
  /**
   * N likes
   * @min 0
   * @max 2147483647
   */
  n_likes?: number;
  /** @uniqueItems true */
  likes?: number[];
}

export interface EventUpdate {
  /** ID */
  id?: number;
  /**
   * Title
   * @minLength 1
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title?: string | null;
  /**
   * Content
   * @maxLength 10000
   */
  content?: string | null;
  /**
   * En content
   * @maxLength 50000
   */
  en_content?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string;
  /**
   * Summary
   * @minLength 1
   * @maxLength 200
   */
  summary?: string;
  /**
   * En summary
   * @maxLength 500
   */
  en_summary?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /** Published */
  published?: boolean;
  /** Created by */
  created_by: number;
  /** Is highlight */
  is_highlight?: boolean;
  /** Status */
  status?: "ACTIVE" | "CANCELLED";
  /**
   * Start at
   * @format date-time
   */
  start_at: string;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  /** Location */
  location?: string | null;
  /** Lat */
  lat?: number | null;
  /** Long */
  long?: number | null;
  /** @uniqueItems true */
  speakers?: number[];
  /** @uniqueItems true */
  event_types?: number[];
  /** Host type */
  host_type?: "OFFLINE" | "ONLINE";
  /** Contact */
  contact?: string | null;
  /** Agendas */
  agendas?: EventAgenda[];
  /** Read only */
  read_only?: boolean;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * En external id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
}

export type DislikeEventRequest = object;

export interface DislikeEventResponse {
  /** Ok */
  ok: boolean;
}

export type LikeEventRequest = object;

export interface LikeEventResponse {
  /** Ok */
  ok: boolean;
}

export interface EventRatingBody {
  /** Rating */
  rating: number;
  /**
   * Comment
   * @minLength 1
   */
  comment?: string;
}

export interface EventRating {
  /** ID */
  id?: number;
  /**
   * Rating
   * @min 0
   * @max 2147483647
   */
  rating: number;
  /**
   * Comment
   * @maxLength 2000
   */
  comment?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** Event */
  event: number;
  /** User */
  user: number;
}

export type EventRegistrationBody = object;

export interface EventRegistration {
  /** ID */
  id?: number;
  /** Status */
  status?: "REGISTERED" | "CANCELLED";
  /** Checked in */
  checked_in?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  user: UserBasicInfo;
  /** Event */
  event: number;
  /** Rating */
  rating?: number;
}

export interface EventRegistrationResponse {
  /** ID */
  id?: number;
  /** Status */
  status?: "REGISTERED" | "CANCELLED" | "CHECKED_IN";
  /** Checked in */
  checked_in?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  user: UserBasicInfo;
  /** Event */
  event: number;
  /** Rating */
  rating?: number;
}

export interface EventCheckInBody {
  /** User id */
  user_id: number;
  /** Group event id */
  group_event_id?: number;
}

export interface Feedback {
  /** ID */
  id?: number;
  /**
   * Rating
   * @min -2147483648
   * @max 2147483647
   */
  rating?: number | null;
  /** Subject */
  subject:
    | "ADMINISTRATIVE_MANAGEMENT"
    | "FACILITIES"
    | "STUDENT_SERVICES"
    | "EXTRACURRICULAR_ACTIVITIES"
    | "CURRICULUM"
    | "FACULTY"
    | "CONSTRUCTION_AND_RENOVATION"
    | "HEALTHCARE"
    | "OTHER";
  /**
   * Description
   * @minLength 1
   * @maxLength 256
   */
  description: string;
  /** Is anonymous */
  is_anonymous?: boolean;
  /** Status */
  status?: "OPEN" | "IN_PROGRESS" | "RESOLVED" | "CLOSED";
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  attachments?: string[] | null;
  /** User */
  user?: number | null;
}

export interface SingleEvent {
  /** Value */
  value: number;
  /**
   * Label
   * @minLength 1
   * @maxLength 200
   */
  label: string;
}

export interface GroupEvent {
  /** ID */
  id?: number;
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /**
   * Sub Title
   * @maxLength 200
   */
  subtitle: string;
  /**
   * En sub title
   * @maxLength 200
   */
  en_subtitle: string;
  /**
   * Description
   * @maxLength 5000
   */
  description: string;
  /**
   * En description
   * @maxLength 5000
   */
  en_description: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /** Published */
  published?: boolean;
  /** Type */
  type: "wow" | null;
  /** Status */
  status?: "UPCOMING" | "ONGOING" | "COMPLETED";
  events: SingleEvent[];
  target_customers?: ReadTargetCustomer[];
  /**
   * Start at
   * @format date-time
   */
  start_at?: string;
  /**
   * End at
   * @format date-time
   */
  end_at?: string;
  /** Ratings */
  ratings?: {
    /** One star */
    one_star?: number;
    /** Two star */
    two_star?: number;
    /** Three star */
    three_star?: number;
    /** Four star */
    four_star?: number;
    /** Five star */
    five_star?: number;
  }[];
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /** Created by */
  created_by?: number | null;
}

export interface GroupEventAdd {
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /**
   * Sub Title
   * @maxLength 200
   */
  subtitle: string;
  /**
   * En sub title
   * @maxLength 200
   */
  en_subtitle: string;
  /**
   * Description
   * @maxLength 5000
   */
  description: string;
  /**
   * En description
   * @maxLength 5000
   */
  en_description: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string | null;
  /** Published */
  published?: boolean;
  /** Type */
  type?: "wow";
  events: number[];
  target_customers?: number[];
}

export interface GroupEventMedia {
  /** ID */
  id?: number;
  /**
   * Title
   * @maxLength 200
   */
  title?: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string | null;
  /** Group event */
  group_event: number;
  /** Total */
  total?: number;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface GroupEventMediaAdd {
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string;
  /** Group event */
  group_event: number;
  images: string[];
}

export interface WebGroupEventMedia {
  /** ID */
  id?: number;
  /**
   * Title
   * @maxLength 200
   */
  title: string;
  /**
   * En title
   * @maxLength 200
   */
  en_title: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string;
  /** Group event */
  group_event: number;
  images: string[];
  /** Total */
  total?: number;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface GroupEventRatingBody {
  /** Rating */
  rating: number;
  /**
   * Comment
   * @minLength 1
   */
  comment?: string;
}

export interface GroupEventRating {
  /** ID */
  id?: number;
  /**
   * Rating
   * @min 0
   * @max 2147483647
   */
  rating: number;
  /**
   * Comment
   * @maxLength 2000
   */
  comment?: string | null;
  /** Group event */
  group_event: number;
  /** User */
  user: number;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface StudentService {
  /** ID */
  id?: number;
  /**
   * Title
   * @maxLength 200
   */
  title?: string;
  /**
   * Description
   * @maxLength 5000
   */
  description?: string;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail: string | null;
  /**
   * Email
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * Phone number
   * @maxLength 50
   */
  phone_number?: string | null;
  /** Location */
  location?: string | null;
  /** Lat */
  lat?: number | null;
  /** Long */
  long?: number | null;
  /**
   * Social name
   * @maxLength 200
   */
  social_name?: string | null;
  /**
   * Social link
   * @maxLength 200
   */
  social_link?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
}

export interface FeedbackBody {
  /**
   * Rating
   * @min -2147483648
   * @max 2147483647
   */
  rating?: number | null;
  /** Subject */
  subject:
    | "ADMINISTRATIVE_MANAGEMENT"
    | "FACILITIES"
    | "STUDENT_SERVICES"
    | "EXTRACURRICULAR_ACTIVITIES"
    | "CURRICULUM"
    | "FACULTY"
    | "CONSTRUCTION_AND_RENOVATION"
    | "HEALTHCARE"
    | "OTHER";
  /**
   * Description
   * @minLength 1
   * @maxLength 256
   */
  description: string;
  attachments?: string[] | null;
}

export interface SurveyForm {
  /** Survey ID */
  id: number;
  /** Title of the survey */
  title?: string;
  description?: string;
  questions?: {
    /** Question ID */
    id?: number;
    /** Text of the question */
    title?: string;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type?:
      | "DATE"
      | "DATETIME"
      | "TEXT"
      | "CHOICES"
      | "RATE"
      | "NUMBER";
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Indicates if the question is required */
    required?: boolean;
    order?: number;
    /** List of options for the question (if applicable) */
    options?: {
      /** label of the option */
      label?: string;
      /** value of the option */
      value?: string;
      order?: number;
    }[];
  }[];
}

export interface SurveyFormAdminList {
  /** Survey ID */
  id: number;
  /** Title of the survey */
  title?: string;
  /** En-Title of the survey */
  en_title?: string;
  description?: string;
  en_description?: string;
  service_type?: "COUNSELLING" | null;
  published?: boolean;
  /** @format datetime */
  created_at?: string;
  /** @format datetime */
  updated_at?: string;
}

export interface SurveyFormAdmin {
  /** Survey ID */
  id: number;
  /** Title of the survey */
  title?: string;
  /** En-Title of the survey */
  en_title?: string;
  description?: string;
  en_description?: string;
  service_type?: "COUNSELLING" | null;
  published?: boolean;
  /** @format datetime */
  created_at?: string;
  /** @format datetime */
  updated_at?: string;
  questions?: {
    /** Question ID */
    id?: number;
    /** Title of the question */
    title?: string;
    /** En-title of the question */
    en_title?: string;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type?:
      | "DATE"
      | "DATETIME"
      | "TEXT"
      | "CHOICES"
      | "RATE"
      | "NUMBER";
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Indicates if the question is required */
    required?: boolean;
    order?: number;
    /** List of options for the question (if applicable) */
    options?: {
      /** label of the option */
      label?: string;
      /** en-label of the option */
      en_label?: string;
      /** value of the option */
      value?: string;
      order?: number;
    }[];
  }[];
}

export interface SurveyAnswerList {
  /** Answer ID */
  id: number;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
  user: UserBasicInfo;
  survey: {
    /** Survey ID */
    id?: number;
    /** Title of the survey */
    title?: string;
    description?: string;
    /** @format datetime */
    created_at?: string;
    /** @format datetime */
    updated_at?: string;
  };
}

export interface SurveyAnswer {
  /** Answer ID */
  id: number;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
  user: UserBasicInfo;
  survey_data: {
    /** Survey ID */
    id?: number;
    /** Title of the survey */
    title?: string;
    /** En title of the survey */
    en_title?: string;
    description?: string;
    /** En-description of the survey */
    en_description?: string;
    service_type?: "COUNSELLING" | null;
    published?: boolean;
    /** @format datetime */
    created_at?: string;
    /** @format datetime */
    updated_at?: string;
    questions?: {
      /** Question ID */
      id?: number;
      /** Text of the question */
      title?: string;
      /** En-title of the question */
      en_title?: string;
      /** Type of the question (date, datetime, choices, number) */
      question_type?: "DATE" | "DATETIME" | "CHOICES" | "RATE" | "NUMBER";
      /** Indicates if the question type is choices */
      allow_custom?: boolean;
      /** Indicates if the question type is choices */
      max_choices?: number | null;
      /** Indicates if the question type is text */
      format?: "EMAIL" | "URL" | null;
      /** Indicates if the question is required */
      required?: boolean;
      order?: number;
      /** List of options for the question (if applicable) */
      options?: {
        /** label of the option */
        label?: string;
        /** en-label of the option */
        en_label?: string;
        /** value of the option */
        value?: string;
        order?: number;
      }[];
      answer?: string[];
    }[];
  };
}

export interface MySurveyAnswerList {
  /** Answer ID */
  id: number;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
  survey: {
    /** Survey ID */
    id?: number;
    /** Title of the survey */
    title?: string;
    description?: string;
    /** @format datetime */
    created_at?: string;
    /** @format datetime */
    updated_at?: string;
  };
}

export interface MySurveyAnswer {
  /** Answer ID */
  id: number;
  /** @format datetime */
  created_at: string;
  /** @format datetime */
  updated_at: string;
  survey_data: {
    /** Survey ID */
    id?: number;
    /** Title of the survey */
    title?: string;
    description?: string;
    questions?: {
      /** Question ID */
      id?: number;
      /** Text of the question */
      title?: string;
      /** Type of the question (date, datetime, choices, number) */
      question_type?: "DATE" | "DATETIME" | "CHOICES" | "RATE" | "NUMBER";
      /** Indicates if the question type is choices */
      allow_custom?: boolean;
      /** Indicates if the question type is choices */
      max_choices?: number | null;
      /** Indicates if the question type is text */
      format?: "EMAIL" | "URL" | null;
      /** Indicates if the question is required */
      required?: boolean;
      order?: number;
      /** List of options for the question (if applicable) */
      options?: {
        /** label of the option */
        label?: string;
        /** value of the option */
        value?: string;
        order?: number;
      }[];
      answer?: string[];
    }[];
  };
}

export interface SurveyFormCreate {
  /** Title of the survey */
  title: string;
  /** En-Title of the survey */
  en_title: string;
  description?: string;
  en_description?: string;
  service_type?: "COUNSELLING" | null;
  published?: boolean;
  questions?: {
    /** Title of the question */
    title: string;
    /** En-title of the question */
    en_title: string;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type: "DATE" | "DATETIME" | "TEXT" | "CHOICES" | "RATE" | "NUMBER";
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Indicates if the question is required */
    required?: boolean;
    order?: number;
    /** List of options for the question (if applicable) */
    options?: {
      /** label of the option */
      label: string;
      /** en-label of the option */
      en_label: string;
      /** value of the option */
      value: string;
      order?: number;
    }[];
  }[];
}

export interface SurveyFormUpdate {
  /** Title of the survey */
  title?: string;
  /** En-Title of the survey */
  en_title?: string;
  description?: string;
  en_description?: string;
  service_type?: "COUNSELLING" | null;
  published?: boolean;
  questions?: {
    /** question id if update or null if create */
    id?: number;
    /** Title of the question */
    title?: string;
    /** En-title of the question */
    en_title?: string;
    /** Type of the question (date, datetime, text, choices, number) */
    question_type?:
      | "DATE"
      | "DATETIME"
      | "TEXT"
      | "CHOICES"
      | "RATE"
      | "NUMBER";
    /** Indicates if the question type is choices */
    allow_custom?: boolean;
    /** Indicates if the question type is choices */
    max_choices?: number | null;
    /** Indicates if the question type is text */
    format?: "EMAIL" | "URL" | null;
    /** Indicates if the question is required */
    required?: boolean;
    order?: number;
    /** List of options for the question (if applicable) */
    options?: {
      /** label of the option */
      label: string;
      /** en-label of the option */
      en_label: string;
      /** value of the option */
      value: string;
      order?: number;
    }[];
  }[];
}

export interface NewCategory {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Icon
   * @format uri
   */
  icon?: string | null;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * En name
   * @maxLength 255
   */
  en_name?: string | null;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /** Parent */
  parent?: number | null;
}

export interface NewsList {
  /** ID */
  id?: number;
  categories?: NewCategory[];
  created_by?: UserBasicInfo;
  /**
   * Title
   * @maxLength 500
   */
  title?: string | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * En External id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
}

export interface News {
  /** ID */
  id?: number;
  categories?: NewCategory[];
  created_by?: UserBasicInfo;
  /**
   * Title
   * @maxLength 500
   */
  title?: string | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * Content
   * @maxLength 500000
   */
  content?: string | null;
  /**
   * En content
   * @maxLength 500000
   */
  en_content?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * Thumbnail
   * @format uri
   */
  thumbnail?: string | null;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * En External id
   * @min -2147483648
   * @max 2147483647
   */
  en_external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
}

export interface NewsCreate {
  /** ID */
  id?: number;
  /**
   * Category IDs
   * @uniqueItems true
   */
  categories?: number[];
  /**
   * Title
   * @maxLength 500
   */
  title?: string | null;
  /**
   * En title
   * @maxLength 500
   */
  en_title?: string | null;
  /**
   * Content
   * @maxLength 500000
   */
  content?: string | null;
  /**
   * En content
   * @maxLength 500000
   */
  en_content?: string | null;
  /**
   * Url
   * @format uri
   * @maxLength 2000
   */
  url?: string | null;
  /**
   * En url
   * @format uri
   * @maxLength 2000
   */
  en_url?: string | null;
  /**
   * Thumbnail
   * @format uri
   * @maxLength 2000
   */
  thumbnail?: string;
  /** Published */
  published?: boolean;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  /**
   * External id
   * @min -2147483648
   * @max 2147483647
   */
  external_id?: number | null;
  /**
   * External thumbnail url
   * @format uri
   * @maxLength 2000
   */
  external_thumbnail_url?: string | null;
}

export interface NotificationTemplate {
  /** ID */
  id?: number;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** Content */
  content: string;
  /**
   * En title
   * @maxLength 255
   */
  en_title: string;
  /** En content */
  en_content: string;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** Data */
  data?: object | null;
  /**
   * Start date
   * @format date
   */
  start_date?: string;
  /**
   * End date
   * @format date
   */
  end_date?: string | null;
  /**
   * Time
   * @format time
   */
  time?: string;
  /** Recurrence */
  recurrence?: "NEVER" | "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY";
  /** Repeat every */
  repeat_every?: number;
  weekdays?:
    | (
        | "MONDAY"
        | "TUESDAY"
        | "WEDNESDAY"
        | "THURSDAY"
        | "FRIDAY"
        | "SATURDAY"
        | "SUNDAY"
      )[]
    | null;
  repeat_by?: "SPECIFIC_DAY" | "RELATIVE_DAY" | null;
  day_of_month?: number | null;
  month_week?: "FIRST" | "SECOND" | "THIRD" | "FOURTH" | "LAST" | null;
  weekday?:
    | "MONDAY"
    | "TUESDAY"
    | "WEDNESDAY"
    | "THURSDAY"
    | "FRIDAY"
    | "SATURDAY"
    | "SUNDAY"
    | null;
  month?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | null;
  /** Active */
  is_active?: boolean;
  /** Status */
  status?:
    | "Created"
    | "Started"
    | "Processing"
    | "Partial Failure"
    | "Completed"
    | "Failed";
  type: NotificationType;
  /** To all user */
  to_all_user?: boolean;
  target_customer?: ReadTargetCustomer;
  related_event?: EventReadInfo;
  created_by?: UserBasicInfo;
}

export interface CreateNotificationTemplateRequest {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string | null;
  /** Type id */
  type: number;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** Content */
  content: string;
  /**
   * En title
   * @maxLength 255
   */
  en_title: string;
  /** En content */
  en_content: string;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** Data */
  data?: object | null;
  /**
   * Start time
   * @format date-time
   */
  start_time?: string | null;
  /** To all user */
  to_all_user?: boolean;
  /** Target customer id */
  target_customer?: number | null;
  /** Related event */
  related_event?: number | null;
  /**
   * Status
   * @default "Created"
   */
  status?:
    | "Created"
    | "Started"
    | "Processing"
    | "Partial Failure"
    | "Completed"
    | "Failed";
}

export interface UpdateNotificationTemplateRequest {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string | null;
  /** Type id */
  type: number;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** Content */
  content: string;
  /**
   * En title
   * @maxLength 255
   */
  en_title: string;
  /** En content */
  en_content: string;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** Data */
  data?: object | null;
  /**
   * Start time
   * @format date-time
   */
  start_time?: string | null;
  /** To all user */
  to_all_user?: boolean;
  /** Target customer id */
  target_customer?: number | null;
  /** Related event */
  related_event?: number | null;
  /**
   * Status
   * @default "Created"
   */
  status?:
    | "Created"
    | "Started"
    | "Processing"
    | "Partial Failure"
    | "Completed"
    | "Failed";
}

export interface NotificationType {
  /** ID */
  id?: number;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * En name
   * @minLength 1
   * @maxLength 50
   */
  en_name: string;
  /**
   * Icon
   * @format uri
   */
  icon?: string | null;
  /** Created by */
  created_by?: number | null;
}

export interface NotificationTypeList {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * En name
   * @minLength 1
   * @maxLength 50
   */
  en_name: string;
}

export interface UserNotification {
  /**
   * Student number
   * @maxLength 255
   */
  student_number?: string | null;
  /** Full name */
  full_name?: string;
}

export interface Notification {
  /** ID */
  id?: number;
  user?: UserNotification;
  type?: NotificationType;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * Title
   * @maxLength 255
   */
  title?: string | null;
  /** Content */
  content?: string | null;
  /**
   * En title
   * @maxLength 255
   */
  en_title?: string | null;
  /** En content */
  en_content?: string | null;
  /** Action type */
  action_type?: "Internal Link" | "External Link" | "Feature" | null;
  /**
   * Action
   * @maxLength 255
   */
  action?: string | null;
  /**
   * En action
   * @maxLength 255
   */
  en_action?: string | null;
  /** Data */
  data?: object | null;
  /** Is read */
  is_read?: boolean;
  /** Created by */
  created_by?: number | null;
}

export interface UnreadNotificationResponse {
  /** Unread count */
  unread_count: number;
}

export interface SpeakerList {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 200
   */
  name: string;
  /**
   * Email
   * @format email
   * @maxLength 254
   */
  email?: string | null;
  /**
   * Phone number
   * @maxLength 15
   */
  phone_number?: string | null;
  /**
   * Job title
   * @maxLength 200
   */
  job_title?: string | null;
  /**
   * Job title
   * @maxLength 200
   */
  en_job_title?: string | null;
  /**
   * Company
   * @maxLength 200
   */
  company?: string | null;
  /**
   * Photo
   * @format uri
   */
  photo?: string | null;
}

export interface SupportRequest {
  /** ID */
  id?: number;
  created_by?: UserBasicInfo;
  record_type_name?: string;
  case_type?: string;
  case_owner_name?: string;
  /**
   * Title
   * @minLength 1
   * @maxLength 255
   */
  subject: string;
  /**
   * Description
   * @minLength 1
   */
  description: string;
  status?: "New" | "In-Progress" | "Resolved" | "Closed";
  attachments?: string[];
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string;
  record_type_object?: {
    id?: number;
    name?: string;
    value?: string;
    case_type?: {
      name?: string;
      value?: string;
    }[];
  };
}

export interface SupportRequestAdd {
  request_type: "Support" | "Complaint";
  record_type_name: string;
  case_type?: string;
  case_owner_name: string;
  /** @default "Low" */
  priority?: string;
  subject: string;
  description: string;
  /** @default [] */
  attachments?: string[];
}

export interface TargetCustomerColleges {
  /**
   * Label
   * @minLength 1
   * @maxLength 255
   */
  label: string;
}

export interface TargetCustomerCohorts {
  /**
   * Label
   * @minLength 1
   * @maxLength 255
   */
  label: string;
}

export interface ReadTargetCustomer {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface TargetCustomer {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** @uniqueItems true */
  users?: UserBasicInfo[];
  created_by?: UserBasicInfo;
  /** Colleges */
  colleges?: string[];
  /**
   * Cohort
   * @minLength 1
   * @maxLength 255
   */
  cohort?: string;
  /** Roles */
  roles?: ("Staff" | "Student")[];
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
}

export interface CreateTargetCustomerRequest {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Roles */
  roles?: ("Staff" | "Student")[];
  /** Colleges */
  colleges?: string[];
  /**
   * Cohort
   * @minLength 1
   * @maxLength 255
   */
  cohort?: string;
  /** @uniqueItems true */
  users?: number[] | null;
}

export interface ImportTargetCustomerResponse {
  /** Target customer id */
  target_customer_id: number | null;
  /**
   * File url
   * @minLength 1
   */
  file_url: string | null;
}

export interface UpdateTargetCustomerRequest {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name?: string;
  /** Roles */
  roles?: ("Staff" | "Student")[];
  /** Colleges */
  colleges?: string[];
  /**
   * New student
   * @default false
   */
  new_student?: boolean;
  /** @uniqueItems true */
  users?: number[] | null;
}

export interface UserDevice {
  /** ID */
  id?: number;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string | null;
  /**
   * Updated at
   * @format date-time
   */
  updated_at?: string | null;
  /**
   * Device token
   * @minLength 1
   * @maxLength 256
   */
  device_token: string;
  /** User */
  user: number;
}

export interface ClassInstructor {
  id: number;
  code?: string;
  name?: string | null;
  display_order?: number;
  instructor_attributes?: {
    id?: number;
    code?: string;
    name?: string | null;
  }[];
  position_name?: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "https://localhost:8000/api/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key],
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];

        if (Array.isArray(property)) {
          property.forEach((value, index) => {
            formData.append(key, value);
          });
        } else {
          formData.append(
            key,
            property instanceof Blob
              ? property
              : typeof property === "object" && property !== null
                ? JSON.stringify(property)
                : `${property}`,
          );
        }

        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title My VinUni API
 * @version 1828672b787eedb6b8ceb50bf75761c68eb30e49
 * @baseUrl https://localhost:8000/api/v1
 *
 * **Release Date:** Tue Nov 12 05:39:42 UTC 2024
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * @description Follow OAuth2 flow to login with Microsoft account - Step 0: The user does login and mobile open this URL in a webview - Step 1: Redirect user to Microsoft login page - Step 2: User login with Microsoft account - Step 3: Microsoft redirect user back to the callback URL to get `code` - Step 4: The backend uses `code` to get `access` and `refresh` token - Step 5: The backend redirects user to login success page with `access token` and `refresh token` in the querystring - Step 6: In the success page, JS code gets the access token and refresh token from the querystring and passes them to mobile via webview communication by the following code: ```javascript const queryString = window.location.search; const urlParams = new URLSearchParams(queryString); const payload = { access_token: urlParams.get('access'), refresh_token: urlParams.get('refresh'), } console.log("Login succeded with payload: ", payload); if (window.flutter_inappwebview) { window.flutter_inappwebview.callHandler("onLoginSucceded", payload); } ``` - Step 7: When mobile receives the payload, it saves the access token and refresh token to the local storage and close the webview Ref: [Webview communication](https://inappwebview.dev/docs/webview/javascript/communication/) **Notes** - Life time of `refresh token` is 7 days - Life time of `access token` is 1 day
     *
     * @tags Auth
     * @name LoginWithMicrosoft
     * @request GET:/auth/ms/login/
     */
    loginWithMicrosoft: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/auth/ms/login/`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Only refresh token will be invalid after calling this API and access token still valid until the end of the day
     *
     * @tags Auth
     * @name Logout
     * @request POST:/auth/logout/
     * @secure
     */
    logout: (
      data: {
        refresh: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/auth/logout/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description The old refresh token always invalid after calling this API
     *
     * @tags Auth
     * @name RefreshToken
     * @request POST:/auth/refresh/
     */
    refreshToken: (
      data: {
        refresh: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            access?: string;
            refresh?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/auth/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  surveys = {
    /**
     * No description
     *
     * @tags Survey
     * @name GetSurveyForm
     * @summary Retrieve the survey form
     * @request GET:/surveys/
     * @secure
     */
    getSurveyForm: (
      query?: {
        /** The type of service to retrieve the corresponding survey form. */
        service_type?: "COUNSELLING";
        /** For filter survey form by form id */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: SurveyForm;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey
     * @name SubmitSurveyForm
     * @summary Submit answers for a survey form
     * @request POST:/surveys/{survey_id}/submit/
     * @secure
     */
    submitSurveyForm: (
      surveyId: number,
      data: {
        /** List of response to the survey questions */
        answers?: {
          /**
           * ID of the question being answered
           * @example 2
           */
          question_id?: number;
          value?: string[];
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            attachment_ids?: string[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /**
             * Error message describing what went wrong
             * @example "Invalid survey ID or missing responses."
             */
            error?: string;
          }
        | {
            /**
             * Error message indicating that the survey or a question was not found
             * @example "Survey not found."
             */
            error?: string;
          }
      >({
        path: `/surveys/${surveyId}/submit/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey
     * @name GetListUserSurvey
     * @summary Get list survey answers with pagination
     * @request GET:/surveys/my-surveys/
     * @secure
     */
    getListUserSurvey: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: MySurveyAnswer[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/my-surveys/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey
     * @name GetDetailUserSurvey
     * @summary Get detail survey answer
     * @request GET:/surveys/my-surveys/{answer_id}
     * @secure
     */
    getDetailUserSurvey: (answerId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: MySurveyAnswer;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/my-surveys/${answerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetAllSurveyForm
     * @summary Get list survey form with pagination
     * @request GET:/surveys/forms/
     * @secure
     */
    getAllSurveyForm: (
      query?: {
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SurveyFormAdminList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name CreateASurveyForm
     * @summary Create a survey form
     * @request POST:/surveys/forms/
     * @secure
     */
    createASurveyForm: (data: SurveyFormCreate, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyFormAdmin;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetDetailSurveyForm
     * @summary Get detail a survey form
     * @request GET:/surveys/forms/{survey_id}/
     * @secure
     */
    getDetailSurveyForm: (surveyId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyFormAdmin;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/${surveyId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name UpdateSurveyForm
     * @summary Update survey form
     * @request PUT:/surveys/forms/{survey_id}/
     * @secure
     */
    updateSurveyForm: (
      surveyId: string,
      data: SurveyFormUpdate,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: SurveyFormAdmin;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/${surveyId}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name DeleteSurveyForm
     * @summary Delete a survey form
     * @request DELETE:/surveys/forms/{survey_id}/
     * @secure
     */
    deleteSurveyForm: (surveyId: string, params: RequestParams = {}) =>
      this.request<
        void,
        {
          /** @example "Survey form not found." */
          detail?: string;
        }
      >({
        path: `/surveys/forms/${surveyId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetListUserSurveyAnswers
     * @summary Get list answers with pagination
     * @request GET:/surveys/forms/answers/
     * @secure
     */
    getListUserSurveyAnswers: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SurveyAnswer[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/answers/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Survey(CMS)
     * @name GetDetailUserSurveyAnswer
     * @summary Get detail answer
     * @request GET:/surveys/forms/answers/{answer_id}
     * @secure
     */
    getDetailUserSurveyAnswer: (answerId: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: SurveyAnswer;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        | {
            /** Error message describing what went wrong */
            error?: string;
          }
        | {
            /** Error message indicating that the survey form was not found */
            error?: string;
          }
      >({
        path: `/surveys/forms/answers/${answerId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  academic = {
    /**
     * No description
     *
     * @tags Academic
     * @name GetAllEnrollments
     * @request GET:/academic/enrollments/
     * @secure
     */
    getAllEnrollments: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            enrollments?: {
              /** Id */
              id?: number;
              /** ProgramVersionName */
              name?: string;
              /** CampusId */
              campus_id?: number;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAcademicProgress
     * @request GET:/academic/enrollments/{id}/progress/
     * @secure
     */
    getAcademicProgress: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            id: number;
            /** @maxLength 255 */
            program_name: string | null;
            /**
             * College
             * @maxLength 255
             */
            college?: string | null;
            /**
             * Cohort
             * @maxLength 255
             */
            cohort?: string | null;
            /** Cummulative gpa */
            cummulative_gpa?: number | null;
            honors?: Honor[];
            self_assignments?: SelfAssignments[];
            code_of_conducts?: CodeOfConduct[];
            projects?: StudentProject[];
            /** Ordered term data for the student */
            terms?: Semester[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/progress/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllCoursesOfAnEnrollment
     * @request GET:/academic/enrollments/{id}/terms/
     * @secure
     */
    getAllCoursesOfAnEnrollment: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            terms?: {
              name?: string;
              courses?: {
                /** Id */
                id?: number;
                /** Name */
                name?: string;
                /** PublishedCode */
                published_code?: string;
                /** SectionCode */
                section?: string;
                /** TermName */
                term?: string;
                /** StatusName */
                status?: string;
                /** LetterGrade */
                letter_grade?: string;
                /** @format float */
                points?: number;
                /**
                 * StartDate
                 * @format date
                 */
                start_date?: string;
                /**
                 * ExpectedEndDate
                 * @format date
                 */
                expected_end_date?: string;
                credits?: number;
                /** InstructorCode */
                instructor?: string;
                code?: string;
              }[];
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/terms/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllAuditsOfAnEnrollment
     * @request GET:/academic/enrollments/{id}/audits/
     * @secure
     */
    getAllAuditsOfAnEnrollment: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            required_credits?: number;
            fulfilled_credits?: number;
            unfulfilled_credits?: number;
            course_category_detail_list?: {
              /** courseCategoryDescription */
              name?: string;
              earned_credits?: number;
              required_credits?: number;
              course_detail_list?: {
                id?: number;
                code?: string;
                credits?: number;
                /** Term code */
                term?: string;
                grade?: string;
                status?: "Fulfilled" | "Unfulfilled" | "Scheduled" | "Future";
              }[];
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/audits/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetDetailCourse
     * @request GET:/academic/enrollments/{id}/courses/{course_code}/
     * @secure
     */
    getDetailCourse: (
      id: number,
      courseCode: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /** Id */
            id?: number;
            /** Name */
            name?: string;
            /** PublishedCode */
            published_code?: string;
            /** SectionCode */
            section?: string;
            /** TermName */
            term?: string;
            /** StatusName */
            status?: string;
            /** LetterGrade */
            letter_grade?: string;
            /** @format float */
            points?: number;
            /**
             * StartDate
             * @format date
             */
            start_date?: string;
            /**
             * ExpectedEndDate
             * @format date
             */
            expected_end_date?: string;
            instructor?: string;
            credits?: number;
            code?: string;
            instructors?: ClassInstructor[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/courses/${courseCode}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetAllSchedulesOfACourse
     * @request GET:/academic/enrollments/{id}/courses/{course_code}/schedules/
     * @secure
     */
    getAllSchedulesOfACourse: (
      id: number,
      courseCode: string,
      query: {
        /** @format date */
        start_date: string;
        /** @format date */
        end_date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            /**
             * SectionStartDate
             * @format date
             */
            start_date?: string;
            /**
             * SectionEndDate
             * @format date
             */
            end_date?: string;
            /** BuildingDetails.BuildingName + RoomNumber */
            location?: string | null;
            schedules?: {
              id?: string;
              /** @format date-time */
              date?: string;
              instructor?: string;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/enrollments/${id}/courses/${courseCode}/schedules/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Academic
     * @name GetListClassesInTheCalendar
     * @request GET:/academic/calendar/classes/
     * @secure
     */
    getListClassesInTheCalendar: (
      query: {
        /** @format date */
        start_date: string;
        /** @format date */
        end_date: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            classes?: {
              course_id?: number;
              course_code?: string;
              course_name?: string;
              course_term?: string;
              /** @format date */
              course_start_date?: string;
              /** @format date */
              course_end_date?: string;
              course_credits?: number;
              instructor?: string;
              /** @format date-time */
              start_time?: string;
              /** @format date-time */
              end_time?: string;
              location?: string;
              section?: string;
              instructors?: ClassInstructor[];
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/academic/calendar/classes/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  accounts = {
    /**
     * No description
     *
     * @tags Group
     * @name GetAllGroups
     * @request GET:/accounts/groups/
     */
    getAllGroups: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Group[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/groups/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group
     * @name CreateAGroup
     * @request POST:/accounts/groups/
     * @secure
     */
    createAGroup: (data: CreateGroupBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: Group;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/groups/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name Register
     * @request POST:/accounts/register/
     */
    register: (data: RegisterBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: User;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/register/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User setting
     * @name RetrieveUserSetting
     * @request GET:/accounts/settings/me/
     * @secure
     */
    retrieveUserSetting: (params: RequestParams = {}) =>
      this.request<
        {
          data?: UserSetting;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/settings/me/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User setting
     * @name UpdateUserSetting
     * @request PUT:/accounts/settings/me/
     * @secure
     */
    updateUserSetting: (data: UserSetting, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserSetting;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/settings/me/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name LoginWithPassword
     * @request POST:/accounts/token/
     */
    loginWithPassword: (data: PasswordLoginBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: PasswordLoginResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/token/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account
     * @name RenewAccessTokenAnRefreshToken
     * @request POST:/accounts/token/refresh/
     */
    renewAccessTokenAnRefreshToken: (
      data: RefreshTokenBody,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: RefreshTokenResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/token/refresh/`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves the user's profile information.
     *
     * @tags Account, CMS
     * @name RetrieveUsersProfile
     * @request GET:/accounts/userinfo/
     * @secure
     */
    retrieveUsersProfile: (params: RequestParams = {}) =>
      this.request<
        {
          data?: UserInfoResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/userinfo/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all users.
     *
     * @tags Account, CMS
     * @name GetAllUsers
     * @request GET:/accounts/users/
     * @secure
     */
    getAllUsers: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /** Search by email, username, first name, last name */
        search?: string;
        roles?: ("Staff" | "Student")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: UserBasicInfo[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/users/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all user attendees.
     *
     * @tags Account
     * @name GetAllUserAttendees
     * @request GET:/accounts/users/attendees/
     * @secure
     */
    getAllUserAttendees: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /** Search by email, username, first name, last name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: UserAttendee[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/users/attendees/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get all user attendees with available status.
     *
     * @tags Account
     * @name GetAllUserAttendeesWithAvailableStatus
     * @request POST:/accounts/users/attendees/available-status/
     * @secure
     */
    getAllUserAttendeesWithAvailableStatus: (
      data: {
        /**
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        start?: string;
        /**
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        end?: string;
        emails?: string[];
      },
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /** Search by email, username, first name, last name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: ExtraUserAttendee[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/users/attendees/available-status/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account, CMS
     * @name GetAllAcademicColleges
     * @request GET:/accounts/students/colleges/
     * @secure
     */
    getAllAcademicColleges: (params: RequestParams = {}) =>
      this.request<
        {
          data?: TargetCustomerColleges[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/students/colleges/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Account, CMS
     * @name GetAllAcademicCohorts
     * @request GET:/accounts/students/cohorts/
     * @secure
     */
    getAllAcademicCohorts: (params: RequestParams = {}) =>
      this.request<
        {
          data?: TargetCustomerCohorts[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/accounts/students/cohorts/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  azureBlobUpload = {
    /**
     * No description
     *
     * @tags Azure blob upload
     * @name UploadAttachments
     * @request POST:/azure-blob-upload/upload-attachments/
     * @secure
     */
    uploadAttachments: (
      data: {
        files: File[];
        thumbnail?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          urls?: string[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/azure-blob-upload/upload-attachments/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  calendar = {
    /**
     * No description
     *
     * @tags Calendar
     * @name GetListEventsFromOutlookCalendar
     * @request GET:/calendar/events/
     * @secure
     */
    getListEventsFromOutlookCalendar: (
      query: {
        /**
         * from date
         * @format date
         * @example "2024/05/14"
         */
        start_date: string;
        /**
         * to date
         * @format date
         * @example "2024/05/14"
         */
        end_date: string;
        /**
         * Time zone
         * @example "Asia/Ho_Chi_Minh"
         */
        tz?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: OutlookEvent[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name BookingAnEvent
     * @request POST:/calendar/events/
     * @secure
     */
    bookingAnEvent: (data: OutlookEventBooking, params: RequestParams = {}) =>
      this.request<
        {
          data?: OutlookEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name DetailABookingEvent
     * @request GET:/calendar/events/{id}/
     */
    detailABookingEvent: (
      id: string,
      query?: {
        /** Time zone */
        tz?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: OutlookEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/${id}/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name UpdateAnEvent
     * @request PUT:/calendar/events/{id}/
     * @secure
     */
    updateAnEvent: (
      id: string,
      data: OutlookEventUpdate,
      params: RequestParams = {},
    ) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name CancelAnEvent
     * @request POST:/calendar/events/{id}/cancel/
     * @secure
     */
    cancelAnEvent: (
      id: string,
      data: OutlookEventCancel,
      params: RequestParams = {},
    ) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/cancel/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name AcceptAnEvent
     * @request POST:/calendar/events/{id}/accept/
     * @secure
     */
    acceptAnEvent: (
      id: string,
      data: OutlookEventResponse,
      params: RequestParams = {},
    ) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/accept/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name TentativeAnEvent
     * @request POST:/calendar/events/{id}/tentative/
     * @secure
     */
    tentativeAnEvent: (
      id: string,
      data: OutlookEventResponse,
      params: RequestParams = {},
    ) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/tentative/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name DeclineAnEvent
     * @request POST:/calendar/events/{id}/decline/
     * @secure
     */
    declineAnEvent: (
      id: string,
      data: OutlookEventResponse,
      params: RequestParams = {},
    ) =>
      this.request<DislikeEventRequest, any>({
        path: `/calendar/events/${id}/decline/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Calendar
     * @name GetListAvailableRoomsToBooking
     * @request GET:/calendar/events/rooms/
     * @secure
     */
    getListAvailableRoomsToBooking: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Page size within the paginated result set. */
        page_size?: number;
        /**
         * from
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        start_time?: string;
        /**
         * to date
         * @format date-time
         * @example "2024-09-12T06:31:00.000Z"
         */
        end_time?: string;
        /**
         * Timezone
         * @example "Asia/Ho_Chi_Minh"
         */
        tz?: string;
        /** Search room by name */
        search?: string;
        /** Get fitness room */
        is_fitness?: boolean;
        /** Get counselling rooms */
        is_counselling?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: CalendarRoom[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/calendar/events/rooms/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  benefits = {
    /**
     * No description
     *
     * @tags Benefit, CMS
     * @name GetListBenefits
     * @request GET:/benefits/
     */
    getListBenefits: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        type?: number[];
        published?: boolean[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: BenefitList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benefit, CMS
     * @name GetDetailBenefit
     * @request GET:/benefits/{id}/
     */
    getDetailBenefit: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: Benefit;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benefit
     * @name GetAllBenefitTypesWithPagination
     * @request GET:/benefits/types/
     */
    getAllBenefitTypesWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: BenefitType[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/types/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Benefit, CMS
     * @name GetAllBenefitTypes
     * @request GET:/benefits/types/all/
     */
    getAllBenefitTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: BenefitType[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/benefits/types/all/`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  storages = {
    /**
     * No description
     *
     * @tags Internal, CMS
     * @name ListCacheKeys
     * @summary Get all cache keys
     * @request GET:/storages/cache/
     * @secure
     */
    listCacheKeys: (
      query?: {
        /** Optional to search keys */
        pattern?: string;
        type?: "string" | "list" | "set" | "zset" | "hash" | "stream";
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: {
              /** Cache key name */
              key?: string;
              type?: string;
              /** Time to live (in seconds) before the key expires */
              ttl?: number;
              /** Memory usage of the cache key in bytes */
              size?: number;
            }[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/storages/cache/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal, CMS
     * @name GetCacheValue
     * @summary Get value of a cache key
     * @request GET:/storages/cache/detail/
     * @secure
     */
    getCacheValue: (
      query: {
        /** The cache key to retrieve */
        key: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Cache key name */
          key?: string;
          /** Value of cache key */
          value?: string;
          /** Time to live (in seconds) before the key expires */
          ttl?: number;
          /**
           * date time of when the cache key was created
           * @format datetime
           * @example "2024-10-25T15:30:00Z"
           */
          created_at?: string;
          /** Memory usage of the cache key in bytes */
          size?: number;
        },
        {
          /** @example "Cache key not found." */
          detail?: string;
        }
      >({
        path: `/storages/cache/detail/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal, CMS
     * @name DeleteCacheKey
     * @summary Delete a cache key
     * @request DELETE:/storages/cache/detail/
     * @secure
     */
    deleteCacheKey: (
      query: {
        /** The cache key to delete */
        key: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        | {
            /** @example "Cache key not found." */
            detail?: string;
          }
        | {
            /** @example "Error deleting cache key." */
            detail?: string;
          }
      >({
        path: `/storages/cache/detail/`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  images = {
    /**
     * No description
     *
     * @tags Internal, CMS
     * @name ResizeImage
     * @request POST:/images/resizing
     */
    resizeImage: (
      data: {
        images: {
          path?: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/images/resizing`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  config = {
    /**
     * No description
     *
     * @tags Internal, CMS
     * @name UpdateAnAppConfigWithCode
     * @request PUT:/config/{code}/
     * @secure
     */
    updateAnAppConfigWithCode: (
      code: string,
      data: {
        emergency_contact?: {
          name?: string;
          en_name?: string;
          phone?: string;
        }[];
        /** @format url */
        library_service_url?: string;
        /** @format url */
        campus_guideline_handbook_url?: string;
        /** @format url */
        internation_student_handbook_url?: string;
        /** @format url */
        wow_handbook_url?: string;
        /** @format url */
        student_policy_url?: string;
        /** @format url */
        faculty_policy_url?: string;
        /** @format url */
        campus_map_url?: string;
        /** @format url */
        code_of_conduct_url?: string;
        /** @format url */
        student_success_url?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: AppConfig;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/config/${code}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Internal, CMS
     * @name GetAnAppConfigWithCode
     * @request GET:/config/{code}/
     * @secure
     */
    getAnAppConfigWithCode: (code: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: AppConfig;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/config/${code}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetListEventsWithPagination
     * @request GET:/events/
     */
    getListEventsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        published?: boolean[];
        is_highlight?: boolean[];
        event_types?: number[];
        host_type?: ("ONLINE" | "OFFLINE")[];
        /** @format date-time */
        start_date?: string;
        /** @format date-time */
        end_date?: string;
        status?: ("UPCOMING" | "ONGOING" | "COMPLETED" | "CANCELLED")[];
        source?: ("INTERNAL" | "EXTERNAL")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: EventList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name CreateAEvent
     * @request POST:/events/
     * @secure
     */
    createAEvent: (data: EventAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name AllEventsMinimalDataForDropList
     * @request GET:/events/all/
     */
    allEventsMinimalDataForDropList: (
      query?: {
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: EventReadInfo[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/all/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetAllEventTypes
     * @request GET:/events/event-types/
     */
    getAllEventTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/event-types/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetListEventTypesWithPagination
     * @request GET:/events/types/
     */
    getListEventTypesWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search name and en_name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: EventType[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name CreateAnEventType
     * @request POST:/events/types/
     * @secure
     */
    createAnEventType: (data: EventType, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetDetailEventType
     * @request GET:/events/types/{id}/
     */
    getDetailEventType: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: EventType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name UpdateAnEventType
     * @request PUT:/events/types/{id}/
     * @secure
     */
    updateAnEventType: (
      id: number,
      data: EventType,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: EventType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/types/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetListRegisteredEvents
     * @request GET:/events/registered/
     * @secure
     */
    getListRegisteredEvents: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @format date */
        start_date?: string;
        /** @format date */
        end_date?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/registered/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name GetListUnratedEvents
     * @request GET:/events/unrated/
     * @secure
     */
    getListUnratedEvents: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/unrated/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetDetailEvent
     * @request GET:/events/{id}/
     */
    getDetailEvent: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name UpdateAnOutlookEvent
     * @request PUT:/events/{id}/
     * @secure
     */
    updateAnOutlookEvent: (
      id: number,
      data: EventUpdate,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: Event;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name DislikeAnEvent
     * @request POST:/events/{id}/dislike/
     * @secure
     */
    dislikeAnEvent: (
      id: number,
      data: DislikeEventRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: DislikeEventResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/dislike/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name LikeAnEvent
     * @request POST:/events/{id}/like/
     * @secure
     */
    likeAnEvent: (
      id: number,
      data: LikeEventRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: LikeEventResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/like/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name RateAnEvent
     * @request POST:/events/{id}/rate/
     * @secure
     */
    rateAnEvent: (
      id: number,
      data: EventRatingBody,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: EventRating;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/rate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name RegisterAnEvent
     * @request POST:/events/{id}/register/
     * @secure
     */
    registerAnEvent: (
      id: number,
      data: EventRegistrationBody,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: EventRegistration;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/register/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name ListEventRegistrationsWithPagination
     * @request GET:/events/{event_id}/registrations/
     */
    listEventRegistrationsWithPagination: (
      eventId: number,
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        status?: ("REGISTERED" | "CHECKED_IN" | "CANCELLED")[];
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: EventRegistrationResponse[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${eventId}/registrations/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event
     * @name CheckInAnEvent
     * @request POST:/events/{id}/check-in/
     * @secure
     */
    checkInAnEvent: (
      id: number,
      data: EventCheckInBody,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: EventRegistration;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/events/${id}/check-in/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  groupEvents = {
    /**
     * No description
     *
     * @tags Group event, CMS
     * @name GetListGroupEventsWithPagination
     * @request GET:/group-events/
     * @secure
     */
    getListGroupEventsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        published?: boolean[];
        type?: "wow"[];
        /** @format date-time */
        start_date?: string;
        /** @format date-time */
        end_date?: string;
        status?: ("UPCOMING" | "ONGOING" | "COMPLETED")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: GroupEvent[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/group-events/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name CreateAGroupEvent
     * @request POST:/group-events/
     * @secure
     */
    createAGroupEvent: (data: GroupEventAdd, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetDetailGroupEvent
     * @request GET:/group-events/{id}/
     * @secure
     */
    getDetailGroupEvent: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name UpdateAGroupEvent
     * @request PUT:/group-events/{id}/
     * @secure
     */
    updateAGroupEvent: (
      id: number,
      data: GroupEventAdd,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetValidGroupEventTypes
     * @request GET:/group-events/valid-types/
     * @secure
     */
    getValidGroupEventTypes: (params: RequestParams = {}) =>
      this.request<
        {
          data?: string[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/valid-types/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name GetValidEvents
     * @request GET:/group-events/valid-events/
     * @secure
     */
    getValidEvents: (params: RequestParams = {}) =>
      this.request<
        {
          data?: SingleEvent[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/valid-events/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name CheckEventConditions
     * @request POST:/group-events/event-conditions/
     * @secure
     */
    checkEventConditions: (
      data: {
        /**
         * Event ids
         * List id of event
         */
        event_ids: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            status: boolean;
            error?: string;
          }[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/event-conditions/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetGroupEventMedia
     * @request GET:/group-events/{id}/media/
     * @secure
     */
    getGroupEventMedia: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/media/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetGroupEventImages
     * @request GET:/group-events/{id}/images/
     * @secure
     */
    getGroupEventImages: (
      id: number,
      query?: {
        media_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: string[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/images/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetGroupEventAttachments
     * @request GET:/group-events/{id}/attachments/
     * @secure
     */
    getGroupEventAttachments: (
      id: number,
      query?: {
        media_id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          images?: {
            thumbnail?: string;
            original?: string;
          }[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/attachments/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name RateAGroupEvent
     * @request POST:/group-events/{id}/rate/
     * @secure
     */
    rateAGroupEvent: (
      id: number,
      data: GroupEventRatingBody,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: GroupEventRating;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/${id}/rate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name GetListUnratedGroupEvents
     * @request GET:/group-events/unrated/
     * @secure
     */
    getListUnratedGroupEvents: (params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/unrated/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event, CMS
     * @name PublishAGroupEvent
     * @request PUT:/group-events/publish/{id}
     * @secure
     */
    publishAGroupEvent: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: GroupEvent;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-events/publish/${id}`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event
     * @name ExportGroupEventRating
     * @request GET:/group-events/export-rating/{id}/
     * @secure
     */
    exportGroupEventRating: (id: number, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/group-events/export-rating/${id}/`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  groupEventMedia = {
    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name GetListGroupEventMediaWithPagination
     * @request GET:/group-event-media/
     * @secure
     */
    getListGroupEventMediaWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
        group_event?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: WebGroupEventMedia[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name CreateAGroupEventMedia
     * @request POST:/group-event-media/
     * @secure
     */
    createAGroupEventMedia: (
      data: GroupEventMediaAdd,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: WebGroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name GetDetailGroupEventMedia
     * @request GET:/group-event-media/{id}/
     * @secure
     */
    getDetailGroupEventMedia: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: WebGroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Group event media, CMS
     * @name UpdateAGroupEventMedia
     * @request PUT:/group-event-media/{id}/
     * @secure
     */
    updateAGroupEventMedia: (
      id: number,
      data: GroupEventMediaAdd,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: WebGroupEventMedia;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/group-event-media/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  feedbacks = {
    /**
     * No description
     *
     * @tags Feedback, CMS
     * @name GetListFeedbacks
     * @request GET:/feedbacks/
     * @secure
     */
    getListFeedbacks: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Feedback[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name CreateAFeedback
     * @request POST:/feedbacks/
     * @secure
     */
    createAFeedback: (data: FeedbackBody, params: RequestParams = {}) =>
      this.request<
        {
          data?: Feedback;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback, CMS
     * @name GetAFeedback
     * @request GET:/feedbacks/{id}/
     * @secure
     */
    getAFeedback: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Feedback;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name UpdateAFeedback
     * @request PUT:/feedbacks/{id}/
     * @secure
     */
    updateAFeedback: (id: number, data: Feedback, params: RequestParams = {}) =>
      this.request<
        {
          data?: Feedback;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/feedbacks/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  studentServices = {
    /**
     * No description
     *
     * @tags Student service
     * @name GetAllStudentService
     * @request GET:/student-services/all/
     * @secure
     */
    getAllStudentService: (params: RequestParams = {}) =>
      this.request<
        {
          data?: StudentService[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/student-services/all/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Student service
     * @name GetDetailStudentService
     * @request GET:/student-services/{id}/
     * @secure
     */
    getDetailStudentService: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: StudentService;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/student-services/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  news = {
    /**
     * No description
     *
     * @tags News, CMS
     * @name GetListNewsWithPagination
     * @request GET:/news/
     */
    getListNewsWithPagination: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        categories?: number[];
        /** @minLength 1 */
        search?: string;
        source?: ("INTERNAL" | "EXTERNAL")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NewsList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name CreateANew
     * @request POST:/news/
     * @secure
     */
    createANew: (data: NewsCreate, params: RequestParams = {}) =>
      this.request<
        {
          data?: News;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetListCategories
     * @request GET:/news/categories/
     */
    getListCategories: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Search by name, en_name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NewCategory[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name CreateACategoryForNew
     * @request POST:/news/categories/
     * @secure
     */
    createACategoryForNew: (data: NewCategory, params: RequestParams = {}) =>
      this.request<
        {
          data?: NewCategory;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetAllCategories
     * @request GET:/news/categories/all/
     */
    getAllCategories: (params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/all/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetACategoryForNew
     * @request GET:/news/categories/{id}/
     */
    getACategoryForNew: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: NewCategory;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name UpdateACategoryForNew
     * @request PUT:/news/categories/{id}/
     * @secure
     */
    updateACategoryForNew: (
      id: number,
      data: NewCategory,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NewCategory;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/categories/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name GetDetailNew
     * @request GET:/news/{id}/
     */
    getDetailNew: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: News;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags News, CMS
     * @name UpdateANew
     * @request PUT:/news/{id}/
     * @secure
     */
    updateANew: (id: number, data: NewsCreate, params: RequestParams = {}) =>
      this.request<
        {
          data?: News;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/news/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notificationTemplates = {
    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name GetListNotificationTemplates
     * @request GET:/notification_templates/
     * @secure
     */
    getListNotificationTemplates: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** search by name, title, en_title */
        search?: string;
        /** filter status */
        status?:
          | "Created"
          | "Started"
          | "Processing"
          | "Partial Failure"
          | "Completed"
          | "Failed";
        /** filter noti by recurrence type */
        recurrence?: string;
        /** filter type by noti type id */
        type?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NotificationTemplate[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name CreateNewNotificationTemplate
     * @request POST:/notification_templates/
     * @secure
     */
    createNewNotificationTemplate: (
      data: CreateNotificationTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NotificationTemplate;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name GetANotificationTemplate
     * @request GET:/notification_templates/{id}/
     * @secure
     */
    getANotificationTemplate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: NotificationTemplate;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name UpdateANotificationTemplate
     * @request PUT:/notification_templates/{id}/
     * @secure
     */
    updateANotificationTemplate: (
      id: string,
      data: UpdateNotificationTemplateRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NotificationTemplate;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification template, CMS
     * @name PushNotificationTemplate
     * @request POST:/notification_templates/{id}/notifications/
     * @secure
     */
    pushNotificationTemplate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_templates/${id}/notifications/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieves the next occurrences of a specified notification template based on its primary key (PK).
     *
     * @tags Notification template, CMS
     * @name GetNextOccurrences
     * @summary Retrieve Next Occurrences
     * @request GET:/notification_templates/{id}/next-occurrences/
     * @secure
     */
    getNextOccurrences: (
      id: number,
      query?: {
        /** The number of occurrences to return. Defaults to 1 if not specified. */
        count?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** An array of datetime strings representing the next occurrences of the notification template. */
          next_occurrences?: string[];
        },
        any
      >({
        path: `/notification_templates/${id}/next-occurrences/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  notificationTypes = {
    /**
     * No description
     *
     * @tags Notification type, CMS
     * @name GetListNotificationTypes
     * @request GET:/notification_types/
     */
    getListNotificationTypes: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** search by name and en_name */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: NotificationTypeList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification type
     * @name CreateANotificationTypes
     * @request POST:/notification_types/
     * @secure
     */
    createANotificationTypes: (
      data: {
        /**
         * @minLength 1
         * @maxLength 50
         */
        name: string;
        /**
         * @minLength 1
         * @maxLength 50
         */
        en_name: string;
        /** @format uri */
        icon: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NotificationType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification type, CMS
     * @name GetANotificationType
     * @request GET:/notification_types/{id}/
     */
    getANotificationType: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: NotificationType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification type
     * @name UpdateANotificationType
     * @request PUT:/notification_types/{id}/
     * @secure
     */
    updateANotificationType: (
      id: string,
      data: {
        /**
         * @minLength 1
         * @maxLength 50
         */
        name?: string | null;
        /**
         * @minLength 1
         * @maxLength 50
         */
        en_name?: string | null;
        /** @format uri */
        icon?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: NotificationType;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notification_types/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags Notification
     * @name GetListNotifications
     * @request GET:/notifications/
     * @secure
     */
    getListNotifications: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: Notification[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name GetListUnreadNotifications
     * @request GET:/notifications/unread_count/
     * @secure
     */
    getListUnreadNotifications: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: UnreadNotificationResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/unread_count/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name GetANotification
     * @request GET:/notifications/{id}/
     * @secure
     */
    getANotification: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: Notification;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name MarkANotificationAsRead
     * @request POST:/notifications/{id}/read/
     * @secure
     */
    markANotificationAsRead: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: object;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/notifications/${id}/read/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  speakers = {
    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetListSpeakers
     * @request GET:/speakers/
     */
    getListSpeakers: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** @minLength 1 */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SpeakerList[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name CreateASpeaker
     * @request POST:/speakers/
     * @secure
     */
    createASpeaker: (data: Speaker, params: RequestParams = {}) =>
      this.request<
        {
          data?: Speaker;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name GetDetailSpeaker
     * @request GET:/speakers/{id}/
     */
    getDetailSpeaker: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: Speaker;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/${id}/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Event, CMS
     * @name UpdateASpeaker
     * @request PUT:/speakers/{id}/
     * @secure
     */
    updateASpeaker: (id: number, data: Speaker, params: RequestParams = {}) =>
      this.request<
        {
          data?: Speaker;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/speakers/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  counselling = {
    /**
     * No description
     *
     * @tags Counselling
     * @name CreateACase
     * @request POST:/counselling/cases
     * @secure
     */
    createACase: (
      data: {
        /** topic name */
        subject: string;
        description?: string;
        /**
         * - room email if is offline booking
         * - null if is online booking
         */
        location_email?: string | null;
        /**
         * - room name if is offline booking
         * - null if is online booking
         */
        location_name?: string | null;
        /** counsellor id */
        counsellor_email: string;
        availability_id: string;
        /** @format date-time */
        start_at: string;
        /** @format date-time */
        end_at: string;
        attachment_ids?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            counselling_id?: string;
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/counselling/cases`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetAllCases
     * @request GET:/counselling/cases
     * @secure
     */
    getAllCases: (params: RequestParams = {}) =>
      this.request<
        {
          cases?: {
            id?: string;
            case_number?: string;
            record_type_name?: string | null;
            request_type?: string;
            case_type?: string;
            priority?: string;
            subject?: string;
            description?: string | null;
            status?:
              | "NEW"
              | "DUPLICATED"
              | "IN_PROGRESS"
              | "RESOLVED"
              | "CANCELED";
            /** @format date-time */
            start_at?: string;
            /** @format date-time */
            end_at?: string;
            counsellor_name?: string;
            location_name?: string | null;
            /** HTML formatted */
            online_meeting_info?: string | null;
            comment?: string | null;
          }[];
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/cases`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetDetailCase
     * @request GET:/counselling/cases/{case_id}/
     * @secure
     */
    getDetailCase: (caseId: string, params: RequestParams = {}) =>
      this.request<
        {
          id?: string;
          case_number?: string;
          record_type_name?: string | null;
          request_type?: string;
          case_type?: string;
          priority?: string;
          subject?: string;
          description?: string | null;
          status?:
            | "NEW"
            | "DUPLICATED"
            | "IN_PROGRESS"
            | "RESOLVED"
            | "CANCELED";
          /** @format date-time */
          start_at?: string;
          /** @format date-time */
          end_at?: string;
          counsellor_name?: string;
          location_name?: string | null;
          /** HTML formatted */
          online_meeting_info?: string | null;
          comment?: string | null;
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/cases/${caseId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name CancelACase
     * @request PUT:/counselling/cases/{case_id}/cancel/
     * @secure
     */
    cancelACase: (caseId: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/cases/${caseId}/cancel/`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetAllTopics
     * @request GET:/counselling/topics/
     * @secure
     */
    getAllTopics: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            id?: string;
            name?: string;
            counsellors?: {
              id?: string;
              name?: string;
              email?: string;
            }[];
          }[];
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/topics/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Counselling
     * @name GetCounsellorAvailabilities
     * @request GET:/counselling/counsellors/{counsellor_id}/availabilities
     * @secure
     */
    getCounsellorAvailabilities: (
      counsellorId: string,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            availabilities?: {
              id?: string;
              /** @format date-time */
              start_date_time?: string;
              /** @format date-time */
              end_date_time?: string;
            }[];
          };
        },
        {
          /** Error information if the request was unsuccessful */
          error?: object;
        }
      >({
        path: `/counselling/counsellors/${counsellorId}/availabilities`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  supportRequests = {
    /**
     * No description
     *
     * @tags Support request, CMS
     * @name GetListSupportRequests
     * @request GET:/support-requests/
     * @secure
     */
    getListSupportRequests: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** search by subject, description or case type */
        search?: string;
        status?: ("New" | "In-Progress" | "Resolved" | "Closed")[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: SupportRequest[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name CreateASupportRequest
     * @request POST:/support-requests/
     * @secure
     */
    createASupportRequest: (
      data: SupportRequestAdd,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: SupportRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name GetASupportRequest
     * @request GET:/support-requests/{id}/
     * @secure
     */
    getASupportRequest: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: SupportRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name GetAllDepartments
     * @request GET:/support-requests/departments/all
     */
    getAllDepartments: (params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            id?: number;
            name?: string;
            value?: string;
            case_types?: {
              name?: string;
              value?: string;
            }[];
            case_owner_name?: string;
          }[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/support-requests/departments/all`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Support request
     * @name CancelASupportRequest
     * @request PUT:/support-requests/{id}/cancel
     * @secure
     */
    cancelASupportRequest: (id: number, params: RequestParams = {}) =>
      this.request<
        {
          data?: {
            ok?: boolean;
          };
        },
        any
      >({
        path: `/support-requests/${id}/cancel`,
        method: "PUT",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  targetCustomers = {
    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name GetListTargetCustomers
     * @request GET:/target_customers/
     * @secure
     */
    getListTargetCustomers: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** page size within the paginated result set. */
        page_size?: number;
        /** Search by name. */
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: ReadTargetCustomer[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name CreateATargetCustomer
     * @request POST:/target_customers/
     * @secure
     */
    createATargetCustomer: (
      data: CreateTargetCustomerRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: CreateTargetCustomerRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer
     * @name ImportUserSetForATargetCustomer
     * @request POST:/target_customers/import_users/
     * @secure
     */
    importUserSetForATargetCustomer: (
      data: {
        /** @format binary */
        file_url: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: ImportTargetCustomerResponse;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/import_users/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name GetATargetCustomer
     * @request GET:/target_customers/{id}/
     * @secure
     */
    getATargetCustomer: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: TargetCustomer;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name UpdateATargetCustomer
     * @request PUT:/target_customers/{id}/
     * @secure
     */
    updateATargetCustomer: (
      id: string,
      data: UpdateTargetCustomerRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: UpdateTargetCustomerRequest;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Target customer, CMS
     * @name GetAllRecipientOfTargetCustomer
     * @request GET:/target_customers/{id}/recipients/
     * @secure
     */
    getAllRecipientOfTargetCustomer: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserBasicInfo[];
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/target_customers/${id}/recipients/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  userDevices = {
    /**
     * No description
     *
     * @tags User device
     * @name GetListUserDevices
     * @request GET:/user_devices/
     * @secure
     */
    getListUserDevices: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: {
            count: number;
            /** @format uri */
            next?: string | null;
            /** @format uri */
            previous?: string | null;
            results: UserDevice[];
          };
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User device
     * @name CreateAUserDevice
     * @request POST:/user_devices/
     * @secure
     */
    createAUserDevice: (data: UserDevice, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserDevice;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User device
     * @name GetAUserDevice
     * @request GET:/user_devices/{id}/
     * @secure
     */
    getAUserDevice: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          data?: UserDevice;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User device
     * @name UpdateAUserDevice
     * @request PUT:/user_devices/{id}/
     * @secure
     */
    updateAUserDevice: (
      id: string,
      data: UserDevice,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: UserDevice;
          /** Error information if the request was unsuccessful */
          error?: object;
        },
        any
      >({
        path: `/user_devices/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
