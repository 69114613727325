import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { MainDashboard } from "@/components/MainDashboard";
import { useAppConfig, useUpdateAppConfig, IAppConfigUpdate } from "@/hooks";
import { internalQueries } from "@/jotai-atoms/internal";

import MobileConfig from "./-components/MobileConfig";

export const Route = createFileRoute("/_auth/_dashboard/_system/config/")({
  component: () => <ConfigPage />,
  beforeLoad: () => {
    return {
      title: "Mobile Config",
    };
  },
});

const ConfigPage = () => {
  const { data: appConfig } = useAppConfig("mobile");
  const { mutate: updateAppConfig, isPending: isUpdateAppConfigLoading } =
    useUpdateAppConfig();
  const queryClient = useQueryClient();
  const { t } = useTranslation("dashboard");
  const onSubmit = (data: IAppConfigUpdate, code: string) => {
    updateAppConfig(
      { ...data, code: code },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.config.message.success.title"),
            description: t("menu.config.message.success.description"),
          });
          void queryClient.invalidateQueries({
            queryKey: internalQueries.appConfig(code).queryKey,
          });
        },
        onError: () => {
          notification.error({
            message: t("menu.config.message.fail.title"),
            description: t("menu.config.message.fail.description"),
          });
        },
      },
    );
  };
  return (
    <MainDashboard>
      <MobileConfig
        type="view"
        data={appConfig.data}
        submitAction={{ onSubmit, isLoading: isUpdateAppConfigLoading }}
      />
    </MainDashboard>
  );
};
