import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { notification } from "antd";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useUserInfo } from "@/jotai-atoms/user";
import { mappingContentToBase64 } from "@/lib/utils";
import { NotificationFormType } from "@/types";
import { NotificationType, ReadTargetCustomer } from "@/types/Api";

import NotificationForm from "../-components/NotificationForm";
import { useAllNotiTypes, useCreateNotification } from "../-data/hooks";
import { notificationCreateLoader } from "../-data/loaders";
import { notificationResolver } from "../-data/validation";
import { MainDashboard } from "../../../../../components/MainDashboard";
import { useAllTargetCustomer } from "../../customers/-data/hooks";
import { useAllEvents } from "../../events/-data/hooks";

export const Route = createFileRoute(
  "/_auth/_dashboard/notifications/_list/add",
)({
  component: () => <AddNotificationPage />,
  beforeLoad: () => {
    return {
      title: t("menu.add", { ns: "dashboard" }),
    };
  },
  loader: notificationCreateLoader,
});

function AddNotificationPage() {
  const form = useForm<NotificationFormType>({
    defaultValues: { title: "", en_title: "", content: "", en_content: "" },
    resolver: notificationResolver,
  });
  const { data: targetCustomerList } = useAllTargetCustomer();
  const { data: userInfo } = useUserInfo();
  const { data: notiTypes } = useAllNotiTypes();
  const { t } = useTranslation("dashboard");
  const { mutate, isPending } = useCreateNotification();
  const navigate = useNavigate();
  const { data: events } = useAllEvents();

  const onSubmit = (data: NotificationFormType) => {
    const mutateData = mappingContentToBase64(data, "content", "en_content");
    mutate(
      {
        ...mutateData,
        created_by: userInfo?.user.id,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t("menu.success"),
            description: t("menu.create.success", {
              what: t("menu.notification.notification"),
            }),
          });
          void navigate({ to: "/notifications" });
        },
        onError: () => {
          notification.error({
            message: t("menu.error"),
            description: t("menu.create.error", {
              what: t("menu.notification.notification"),
            }),
          });
        },
      },
    );
  };

  return (
    <MainDashboard>
      <NotificationForm
        title={t("menu.add")}
        form={form}
        notificationType={(notiTypes as NotificationType[] | undefined) ?? []}
        targetCustomers={
          (targetCustomerList as ReadTargetCustomer[] | undefined) ?? []
        }
        events={events ?? []}
        createBy={userInfo?.user.email ?? ""}
        onSubmit={onSubmit}
        isSubmitLoading={isPending}
        isEdit
      />
    </MainDashboard>
  );
}
