import {
  BellOutlined,
  CalendarOutlined,
  FormOutlined,
  GiftOutlined,
  MessageOutlined,
  ProfileOutlined,
  TeamOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useLocation, Link } from "@tanstack/react-router";
import { Layout, Menu, MenuProps, Typography } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { t } from "i18next";
import { SetStateAction } from "jotai";
import { Dispatch, memo, useState } from "react";

import { Stack } from "@/components";
import { FEATURE_FLAG_COUNSELLING_SURVEY } from "@/lib/constants";
import { cn } from "@/lib/utils";

const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const Style = memo(() => {
  return (
    <style>
      {`    .app_sidebar {
              .ant-menu-submenu {
                margin: 4px 0;
              }
              .ant-menu-submenu-title {
                margin: 0px;
                position: relative;
                &::after{
                  content: "";
                  opacity: 0;
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  border-right: 3px solid var(--color-brand-secondary);
                }
              }
              .ant-menu-item.ant-menu-item-selected,
              .ant-menu-submenu-selected:not(:has(.ant-menu-item-selected))
              {
                  background-color: var(--color-brand-primary);
                  a {
                    color: white;
                  }
                  &, .ant-menu-submenu-arrow{
                    color: var(--ant-primary-1);
                  }
                  .ant-menu-submenu-title {
                    &::after{
                      opacity: 1;
                    }
                    &:hover{
                      color: var(--ant-primary-1);
                    }

                    a:hover {
                      color: var(--ant-primary-1);
                    }
                  }
              }
              .ant-menu-item.ant-menu-item-selected::after, 
              .ant-menu-submenu-selected::after {
                border-right-color: var(--color-brand-secondary)
              } 
            }
            
        `}
    </style>
  );
});

const useMenuItems = (): {
  menuItems: ItemType[];
  currentGroup: string;
  currentPage: string;
  groupKeys: string[];
} => {
  const { pathname } = useLocation();

  const menuItems: MenuItem[] = [
    {
      label: t("menu.targetCustomer.targetCustomer", { ns: "dashboard" }),
      key: "customers",
      icon: <TeamOutlined />,
      children: [
        {
          label: (
            <Link to="/customers">{t("menu.list", { ns: "dashboard" })}</Link>
          ),
          key: "/customers",
        },
      ],
    },
    {
      label: t("menu.notifications", { ns: "dashboard" }),
      key: "notifications",
      icon: <BellOutlined />,
      children: [
        {
          label: (
            <Link to="/notifications">
              {t("menu.list", { ns: "dashboard" })}
            </Link>
          ),
          key: "/notifications",
        },
        {
          label: <Link to="/notifications/types">Types</Link>,
          key: "/notifications/types",
        },
      ],
    },
    {
      label: t("menu.news.news", { ns: "dashboard" }),
      icon: <ProfileOutlined />,
      key: "news",
      children: [
        {
          label: <Link to="/news">{t("menu.list", { ns: "dashboard" })}</Link>,
          key: "/news",
        },
        {
          label: (
            <Link to="/news/category">
              {t("menu.news.categories", { ns: "dashboard" })}
            </Link>
          ),
          key: "/news/category",
        },
      ],
    },
    {
      label: t("menu.events.events", { ns: "dashboard" }),
      icon: <CalendarOutlined />,
      key: "events",
      children: [
        {
          label: (
            <Link to="/events/speakers">
              {t("menu.speaker.list", { ns: "dashboard" })}
            </Link>
          ),
          key: "/events/speakers",
        },
        {
          label: (
            <Link to="/events/types">
              {t("menu.events.types", { ns: "dashboard" })}
            </Link>
          ),
          key: "/events/types",
        },
        {
          label: (
            <Link to="/events">
              {t("menu.events.single", { ns: "dashboard" })}
            </Link>
          ),
          key: "/events",
        },
        {
          label: (
            <Link to="/events/groups">
              {t("menu.events.group", { ns: "dashboard" })}
            </Link>
          ),
          key: "/events/groups",
        },
      ],
    },
    {
      label: t("menu.serviceRequest.serviceRequest", { ns: "dashboard" }),
      icon: <FormOutlined />,
      key: "service-requests",
      children: [
        {
          label: (
            <Link to="/service-requests">
              {t("menu.list", { ns: "dashboard" })}
            </Link>
          ),
          key: "/service-requests",
        },
      ],
    },
    {
      label: t("menu.feedbacks", { ns: "dashboard" }),
      icon: <MessageOutlined />,
      key: "feedbacks",
      children: [
        {
          label: (
            <Link to="/feedbacks">{t("menu.list", { ns: "dashboard" })}</Link>
          ),
          key: "/feedbacks",
        },
      ],
    },
    {
      label: t("menu.benefits.benefits", { ns: "dashboard" }),
      key: "/benefits-group",
      icon: <GiftOutlined />,
      children: [
        {
          label: (
            <Link to="/benefits">{t("menu.list", { ns: "dashboard" })}</Link>
          ),
          key: "/benefits",
        },
        {
          label: (
            <Link to="/benefits/types">
              {t("menu.benefits.type.types", { ns: "dashboard" })}
            </Link>
          ),
          key: "/benefits/types",
        },
      ],
    },
    ...(FEATURE_FLAG_COUNSELLING_SURVEY
      ? [
          {
            label: t("menu.counsellingService.counsellingService", {
              ns: "dashboard",
            }),
            key: "/counselling-service-group",
            icon: <GiftOutlined />,
            children: [
              {
                label: (
                  <Link to="/counselling-service/survey">
                    {t("menu.counsellingService.surveyConfiguration", {
                      ns: "dashboard",
                    })}
                  </Link>
                ),
                key: "/counselling-service/survey",
              },
            ],
          },
        ]
      : []),
    {
      label: "System",
      key: "system",
      icon: <SettingOutlined />,
      children: [
        {
          label: <Link to="/config">Config</Link>,
          key: "/config",
        },
        {
          label: <Link to="/cache">Cache</Link>,
          key: "/cache",
        },
      ],
    },
  ];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const groupKeys: string[] = menuItems.map((item) => item.key);

  const levels = pathname.split("/").filter((item) => item.length > 0);
  const currentGroup = levels[0];

  const matches = menuItems
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    .map((item) => item.children || [])
    .flat()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    .filter((item) => pathname.includes(item.key))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    .sort((a, b) => b.key.length - a.key.length);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const currentPage: string = matches.length > 0 ? matches[0].key : "/";

  return { menuItems, currentGroup, currentPage, groupKeys };
};

interface Props {
  collapsed: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({ collapsed }: Props) => {
  const { menuItems, currentGroup, currentPage, groupKeys } = useMenuItems();
  const [openKeys, setOpenKeys] = useState([currentGroup]);
  const buildVersion = import.meta.env.VITE_BUILD_VERSION || "";

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!groupKeys.includes(latestOpenKey!)) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Style />
      <Sider
        className="fixed bottom-0 left-0 top-0 h-screen"
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
      >
        <Link to="/">
          <Stack className="gap-3 p-4">
            <img src="/vinuni.png" className="h-8 w-8" />
            {!collapsed && (
              <Typography className="text-lg font-medium text-brand-primary">
                VINUNI
              </Typography>
            )}
          </Stack>
        </Link>
        <Menu
          mode="inline"
          className="app_sidebar"
          items={menuItems}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultOpenKeys={[currentGroup]}
          selectedKeys={[currentPage]}
        />
        {buildVersion.length > 0 && (
          <div
            className={cn(
              "fixed bottom-10 z-10",
              collapsed ? "left-1.5" : "left-2.5",
            )}
          >
            <span>
              <Typography.Text
                className={cn(
                  "block duration-300",
                  collapsed ? "translate-x-0" : "translate-x-7",
                )}
              >
                {collapsed ? "v" : "Version"}: {buildVersion.slice(0, 8)}
              </Typography.Text>
            </span>
          </div>
        )}
      </Sider>
    </>
  );
};
