import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";
import { SupportRequest } from "@/types/Api";

const Keys = {
  index: "support-request",
  detail: "detail",
  list: "list",
} as const;

export const serviceRequestQueries = {
  list: (params: FilterServiceRequestType) => {
    return queryOptions({
      queryKey: [Keys.index, Keys.list, params] as const,
      queryFn: async () => {
        try {
          const { data } =
            await fetchApiClient.supportRequests.getListSupportRequests(params);
          return data;
        } catch (error) {
          throw new Error("Something went wrong!");
        }
      },
      placeholderData: keepPreviousData,
    });
  },
};

export interface FilterServiceRequestType extends PaginateFilterState {
  status?: Exclude<SupportRequest["status"], undefined>[];
}

const filter = atomWithDebounce<FilterServiceRequestType>({
  page: 1,
  search: "",
  status: [],
});

export const serviceRequestAtoms = {
  filter,
  list: atomWithSuspenseQuery((get) =>
    serviceRequestQueries.list(get(filter.debouncedValueAtom)),
  ),
};
