import ky from "ky";

import { globalStore } from "@/jotai-atoms";
import { authAtom } from "@/jotai-atoms/oidc";
import { authUserAtom } from "@/jotai-atoms/user";
import { Api } from "@/types/Api.ts";

import { BACKEND_API_URI } from "../constants";

export const fetchWithToken = async (
  url: string | URL | RequestInfo,
  init?: RequestInit,
) => {
  const auth = globalStore.get(authAtom);
  const authUser = await auth.getUser();
  if (!authUser?.id_token) {
    void auth.signinRedirect();
    throw new Error("No auth user found");
  }
  const options = init ? init : {};
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${authUser.id_token}`,
    "Authorization-Provider": "microsoft",
    "Accept-Language": "vi-VN",
  };

  try {
    const res = await fetch(url, options);
    if (res.status === 401) {
      const data = await auth.signinSilent().catch((err: unknown) => {
        console.error("Failed to silently renew token", err);
      });
      if (data && data.id_token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${data.id_token}`,
        };
        return await fetch(url, options);
      }
    }
    return res;
  } catch (error) {
    throw new Error("Something went wrong!");
  }
};

const kyFetch = ky.create({
  prefixUrl: BACKEND_API_URI,
  retry: 0,
  hooks: {
    beforeRequest: [
      async (request) => {
        const { data: authUser } = await globalStore.get(authUserAtom);
        if (!authUser?.id_token) {
          throw new Error("No auth user found");
        }
        request.headers.set("Authorization", `Bearer ${authUser.id_token}`);
        request.headers.set("Authorization-Provider", `microsoft`);
        request.headers.set("Content-type", `application/json`);
      },
    ],
  },
});

const fetchApi = kyFetch;

const fetchApiUnauth = ky.create({
  prefixUrl: BACKEND_API_URI,
});

// Fetch client for generated types
const fetchApiClient = new Api({
  baseUrl: BACKEND_API_URI,
  customFetch: fetchWithToken,
});

export { fetchApi, fetchApiUnauth, fetchApiClient };
