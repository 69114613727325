import { useNavigate } from "@tanstack/react-router";
import { Form, Input, Select, Typography } from "antd";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  LayoutDetailsForm,
  LayoutDetailsFormProps,
  DatePicker,
  Editor,
} from "@/components";
import { NotificationFormType } from "@/types";
import {
  EventReadInfo,
  NotificationType,
  ReadTargetCustomer,
} from "@/types/Api";

import { DATE_ONLY, HOUR_ONLY, NOTIFICATION_ACTIONS } from "../-data";
import { NotificationStatisticType } from "../-data/store";
import NotificationStatisticTable from "./NotificationStatisticTable";
import Recipients from "./Recipients";
import Recurrence from "./Recurrence";

interface Props extends LayoutDetailsFormProps<NotificationFormType> {
  notificationType: NotificationType[];
  statistic?: NotificationStatisticType[];
  targetCustomers: ReadTargetCustomer[];
  events: EventReadInfo[];
  createBy: string;
}

const NotificationForm = ({
  title,
  isEdit,
  onEdit,
  form,
  notificationType,
  createBy,
  onSubmit,
  targetCustomers,
  isSubmitLoading,
  events,
  rightContent,
  statistic = [],
}: Props) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleClose = () => {
    void navigate({ to: "/notifications" });
  };

  return (
    <LayoutDetailsForm
      onSubmit={onSubmit}
      isEdit={isEdit}
      onEdit={onEdit}
      isSubmitLoading={isSubmitLoading}
      form={form}
      onClose={handleClose}
      title={title}
      rightContent={rightContent}
    >
      <Controller
        control={form.control}
        name="en_title"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enTitle")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Input
                {...field}
                disabled={!isEdit}
                value={field.value}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="title"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.vnTitle")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              required
              help={error?.message}
            >
              <Input
                {...field}
                status={error && "error"}
                disabled={!isEdit}
                value={field.value}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="en_content"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.enContent")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="content"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.content")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Editor
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="type"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.notification.notificationType")}
                </Typography.Text>
              }
              className="col-span-2"
              validateStatus={error ? "error" : ""}
              help={error?.message}
              required
            >
              <Select
                {...field}
                showArrow
                maxTagTextLength={24}
                options={notificationType.map((item) => {
                  return {
                    label: item.en_name || item.name,
                    value: item.id,
                  };
                })}
                placeholder={t("menu.select", {
                  what: t("menu.notification.notificationType"),
                })}
                disabled={!isEdit}
                value={field.value}
              />
            </Form.Item>
          );
        }}
      />
      <Recipients
        targetCustomers={targetCustomers}
        form={form}
        isEdit={isEdit}
        events={events}
      />
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.notification.typeOfAction")}
          </Typography.Text>
        }
        className="col-span-2 mb-0"
      >
        <div className="flex gap-2">
          <Controller
            control={form.control}
            name="action_type"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  validateStatus={error ? "error" : ""}
                  help={error?.message}
                >
                  <Select
                    {...field}
                    showArrow
                    maxTagTextLength={24}
                    options={NOTIFICATION_ACTIONS}
                    placeholder={t("menu.select", {
                      what: t("menu.notification.linkType"),
                    })}
                    disabled={!isEdit}
                    value={field.value}
                  />
                </Form.Item>
              );
            }}
          />
          <Controller
            control={form.control}
            name="en_action"
            render={({ field, fieldState: { error } }) => {
              return (
                <Form.Item
                  className="flex-1"
                  validateStatus={error ? "error" : ""}
                >
                  <Input
                    {...field}
                    disabled={!isEdit}
                    value={field.value ?? ""}
                    placeholder={t("menu.enterHere")}
                  />
                </Form.Item>
              );
            }}
          />
        </div>
      </Form.Item>
      <Controller
        control={form.control}
        name="start_date"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.date")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              required
              help={error?.message}
            >
              <DatePicker
                {...field}
                className="w-full"
                disabled={!isEdit}
                value={field.value ? dayjs(field.value, DATE_ONLY) : null}
                placeholder={t("menu.select", {
                  what: t("menu.date"),
                })}
              />
            </Form.Item>
          );
        }}
      />
      <Controller
        control={form.control}
        name="time"
        render={({ field, fieldState: { error } }) => {
          return (
            <Form.Item
              label={
                <Typography.Text className="font-medium">
                  {t("menu.time")}
                </Typography.Text>
              }
              validateStatus={error ? "error" : ""}
              required
              help={error?.message}
            >
              <DatePicker
                {...field}
                onChange={(e) => {
                  field.onChange(e ? dayjs(e).format(HOUR_ONLY) : null);
                }}
                picker="time"
                className="w-full"
                disabled={!isEdit}
                value={field.value ? dayjs(field.value, HOUR_ONLY) : null}
                placeholder={t("menu.select", {
                  what: t("menu.time"),
                })}
              />
            </Form.Item>
          );
        }}
      />
      <Recurrence isEdit={!!isEdit} form={form} />
      <Form.Item
        label={
          <Typography.Text className="font-medium">
            {t("menu.notification.createdBy")}
          </Typography.Text>
        }
        className="col-span-2"
      >
        <Input disabled value={createBy} />
      </Form.Item>
      {statistic.length > 0 && (
        <Form.Item
          label={
            <Typography.Text className="font-medium">
              {t("menu.notification.statistics")}
            </Typography.Text>
          }
          className="col-span-2"
        >
          <NotificationStatisticTable statistic={statistic} />
        </Form.Item>
      )}
    </LayoutDetailsForm>
  );
};

export default NotificationForm;
