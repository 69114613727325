import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { ReactNode } from "@tanstack/react-router";
import { Button, Input, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Stack } from "@/components/Stack";

interface Props {
  search?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  isLoading?: boolean;
  title?: string;
  onAddNewClick?: () => void;
  placeholder?: string;
  disableSearch?: boolean;
  filter?: ReactNode;
}

export const ListHeader = ({
  search,
  onSearch,
  isLoading,
  onChange,
  title,
  onAddNewClick,
  placeholder,
  filter,
}: Props) => {
  const { t } = useTranslation("dashboard");
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  return (
    <Stack className="mb-4 justify-between">
      <Typography.Title className="!mb-0" level={4}>
        {title}
      </Typography.Title>
      <div className="flex flex-1 items-center justify-end gap-4">
        {!isOpenSearch ? (
          onChange && (
            <Stack
              className="cursor-pointer gap-1"
              onClick={() => {
                setIsOpenSearch(true);
              }}
            >
              <span>{t("menu.search.search")}</span>
              <SearchOutlined className="text-[#8C8C8C]" />
            </Stack>
          )
        ) : (
          <Input.Search
            autoFocus
            value={search}
            // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
            placeholder={placeholder || "Search"}
            className="w-1/3"
            onSearch={onSearch ?? onChange}
            loading={isLoading}
            onChange={(e) => {
              onChange?.(e.target.value);
            }}
            onBlur={() => {
              setIsOpenSearch(false);
            }}
          />
        )}
        {filter}
        {onAddNewClick && (
          <Button
            className="border-brand bg-brand-primary"
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddNewClick}
          >
            {t("menu.addNew")}
          </Button>
        )}
      </div>
    </Stack>
  );
};
