import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Button, Input, Form, Typography, notification } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Editor, LayoutDetailsForm } from "@/components";
import { MainDashboard } from "@/components/MainDashboard";
import { QUESTION_TYPE } from "@/lib/constants";
import { checkQuestionTypeIsChoice } from "@/lib/utils";
import { ISurveyForm } from "@/types";
import { SurveyForm } from "@/types/Api";

import Question from "../-components/Question";
import { useSurvey, useUpdateSurvey } from "../-data/hooks";
import { surveyLoader } from "../-data/loaders";
import { counsellingResolver } from "../-data/validation";

export const Route = createFileRoute(
  "/_auth/_dashboard/counselling-service/survey/",
)({
  component: () => <CounsellingServicePage />,
  beforeLoad: () => ({
    title: t("menu.counsellingService.surveyConfiguration", {
      ns: "dashboard",
    }),
  }),
  loader: surveyLoader,
});

function CounsellingServicePage() {
  const { data } = useSurvey();
  const form = useForm<ISurveyForm>({
    defaultValues: {
      ...data,
      questions: data?.questions?.map((item) => {
        return {
          ...item,
          question_type:
            item.question_type === QUESTION_TYPE.CHOICES
              ? (item.max_choices ?? 0) > 1
                ? "CHECKBOXES"
                : "MULTIPLE_CHOICE"
              : item.question_type,
        };
      }),
    },
    resolver: counsellingResolver,
  });
  const { control } = form;
  const { append, remove, fields, insert } = useFieldArray({
    control,
    name: "questions",
  });

  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation("dashboard");

  const { mutate, isPending } = useUpdateSurvey();

  const handleOnClose = () => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      void navigate({ to: "/customers" });
    }
  };

  const handleSubmit = (data: ISurveyForm) => {
    const mutateData = {
      ...data,
      questions: data.questions?.map((item, index) => {
        const isChoice = checkQuestionTypeIsChoice(item.question_type);
        return {
          ...item,
          order: index + 1,
          question_type: isChoice
            ? (QUESTION_TYPE.CHOICES as NonNullable<
                SurveyForm["questions"]
              >[number]["question_type"])
            : (item.question_type as NonNullable<
                SurveyForm["questions"]
              >[number]["question_type"]),
          options: item.options?.map((item) => ({
            ...item,
            label: item.label ?? "",
            en_label: item.en_label ?? "",
            value: item.value ?? "",
          })),
        };
      }),
    };
    mutate(mutateData, {
      onSuccess: () => {
        notification.success({
          message: t("menu.success"),
          description: t("menu.update.success", {
            what: t("menu.counsellingService.survey").toLowerCase(),
          }),
        });
        setIsEdit(false);
      },
      onError: () => {
        notification.error({
          message: t("menu.error"),
          description: t("menu.update.error", {
            what: t("menu.counsellingService.survey").toLowerCase(),
          }),
        });
      },
    });
  };

  return (
    <MainDashboard>
      <LayoutDetailsForm
        form={form}
        title={t("menu.counsellingService.surveyConfiguration")}
        isEdit={isEdit}
        onEdit={() => {
          setIsEdit(true);
        }}
        isSubmitLoading={isPending}
        onSubmit={handleSubmit}
        onClose={handleOnClose}
      >
        <Form.Item
          className="col-span-2"
          label={
            <Typography.Text className="font-medium">
              {t("menu.enTitle")}
            </Typography.Text>
          }
          required
        >
          <Controller
            name="en_title"
            control={control}
            disabled={!isEdit}
            render={({ field }) => (
              <Input {...field} placeholder={t("menu.enterHere")} />
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Typography.Text className="font-medium">
              {t("menu.enDescription")}
            </Typography.Text>
          }
          className="col-span-2"
        >
          <Controller
            name="en_description"
            control={control}
            render={({ field }) => (
              <Editor
                placeholder={t("menu.enterHere")}
                disabled={!isEdit}
                data={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Typography.Text className="font-medium">
              {t("menu.counsellingService.question")}
            </Typography.Text>
          }
          className="col-span-2 "
          required
        >
          <div className="border border-solid border-[#d9d9d9] py-4 px-6">
            {fields.length === 0 && (
              <div>
                <p className="mb-2">No questions</p>
                <Button
                  className="border-brand bg-brand-primary"
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={!isEdit}
                  onClick={() => {
                    append({
                      title: "",
                      question_type: "TEXT",
                    });
                  }}
                >
                  {t("menu.addNew")}
                </Button>
              </div>
            )}
            {fields.map((question, index: number) => {
              return (
                <>
                  <Question
                    form={form}
                    key={question.id}
                    disable={!isEdit}
                    index={index}
                  />
                  <div className="flex justify-between items-center mt-4">
                    <Button
                      className="border-brand bg-brand-primary"
                      type="primary"
                      icon={<PlusOutlined />}
                      disabled={!isEdit}
                      onClick={() => {
                        insert(index + 1, {
                          title: "",
                          question_type: "TEXT",
                        });
                      }}
                    >
                      {t("menu.addNew")}
                    </Button>

                    <Button
                      danger
                      disabled={!isEdit}
                      icon={<DeleteOutlined />}
                      type="primary"
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      {t("menu.button.delete")}
                    </Button>
                  </div>
                  <div
                    style={{ border: "1px dashed #444" }}
                    className=" my-2 mt-4"
                  />
                </>
              );
            })}
          </div>
        </Form.Item>
      </LayoutDetailsForm>
    </MainDashboard>
  );
}
