import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import atomWithDebounce from "@/jotai-atoms/atomWithDebounce";
import { fetchApi, fetchApiClient } from "@/lib/utils/fetchUtils";
import { PaginateFilterState } from "@/types";
import { NotificationTemplate } from "@/types/Api";

type NotificationListFilterType = Parameters<
  typeof fetchApiClient.notificationTemplates.getListNotificationTemplates
>["0"];

export type NotificationListFilter = Omit<
  NonNullable<NotificationListFilterType>,
  "page" | "search"
>;

export interface NotificationStatisticType {
  sent_at: string;
  view_count: number;
  success_user_count: number;
  fail_user_count: number;
  total_user_count: number;
  success_device_count: number;
  fail_device_count: number;
  total_device_count: 3;
  status: NotificationTemplate["status"];
}

export const notificationQueries = {
  list: (params: NotificationListFilterType) =>
    queryOptions({
      queryKey: ["notifications-list", params],
      queryFn: async () => {
        const response =
          await fetchApiClient.notificationTemplates.getListNotificationTemplates(
            params,
          );
        return response.data;
      },
      placeholderData: keepPreviousData,
    }),
  details: (id: string) =>
    queryOptions({
      queryKey: ["notification-details", id],
      queryFn: async () => {
        const response =
          await fetchApiClient.notificationTemplates.getANotificationTemplate(
            id,
          );
        return response.data;
      },
    }),
  allNotiTypes: () =>
    queryOptions({
      queryKey: ["notification-types-list", "all"],
      queryFn: async () => {
        const response =
          await fetchApiClient.notificationTypes.getListNotificationTypes();
        return response.data;
      },
    }),
  listNotiTypes: (params: PaginateFilterState) =>
    queryOptions({
      queryKey: ["notification-types-list", params],
      queryFn: async () => {
        const response =
          await fetchApiClient.notificationTypes.getListNotificationTypes({
            page: params.page,
            search: params.search,
          });
        return response.data;
      },
      placeholderData: keepPreviousData,
    }),
  notiTypesDetails: (id: string) =>
    queryOptions({
      queryKey: ["notification-types-details", id],
      queryFn: async () => {
        const response =
          await fetchApiClient.notificationTypes.getANotificationType(id);
        return response.data;
      },
    }),
  statistics: (id: string) =>
    queryOptions({
      queryKey: ["notification-details", "statistics", id],
      queryFn: async () => {
        const response = await fetchApi(
          `notification_templates/${id}/statistics/`,
        );
        const result = await response.json<{
          data?: NotificationStatisticType[];
        }>();
        return result.data;
      },
    }),
};

const notificationListFilterAtom = atomWithDebounce<NotificationListFilterType>(
  {
    page: 1,
    search: "",
  },
);

const notiTypesFilterAtom = atomWithDebounce<PaginateFilterState>({
  page: 1,
  search: "",
});

export const notificationAtoms = {
  filter: notificationListFilterAtom,
  list: atomWithSuspenseQuery((get) => {
    return notificationQueries.list(
      get(notificationListFilterAtom.debouncedValueAtom),
    );
  }),
  details: atomFamily((id: number | string) =>
    atomWithSuspenseQuery(() => notificationQueries.details(id.toString())),
  ),
  notiTypeFilter: notiTypesFilterAtom,
  listNotiTypes: atomWithSuspenseQuery((get) => {
    return notificationQueries.listNotiTypes(
      get(notiTypesFilterAtom.debouncedValueAtom),
    );
  }),
  allNotiTypes: atomWithSuspenseQuery(() => {
    return notificationQueries.allNotiTypes();
  }),
  notiTypeDetails: atomFamily((id: number | string) =>
    atomWithSuspenseQuery(() =>
      notificationQueries.notiTypesDetails(id.toString()),
    ),
  ),
  statistic: atomFamily((id: number | string) =>
    atomWithSuspenseQuery(() => notificationQueries.statistics(id.toString())),
  ),
};
