import { Form, Input, Select, Typography } from "antd";
import { Controller, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { QUESTION_TYPE } from "@/lib/constants";
import { checkQuestionTypeIsChoice } from "@/lib/utils";
import { ISurveyForm } from "@/types";

import QuestionType from "./QuestionType";

interface Props {
  index: number;
  form: UseFormReturn<ISurveyForm>;
  disable: boolean;
}

const Question = ({ index, form, disable }: Props) => {
  const { control } = form;
  const { t } = useTranslation("dashboard");

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const controlName = `questions.${index}` as const;

  const questionType = useWatch({
    control,
    name: `${controlName}.question_type`,
  });

  const shouldRenderQuestionOptions =
    checkQuestionTypeIsChoice(questionType) || questionType === "RATE";

  return (
    <>
      <Typography.Text className="font-medium mt-2">
        {t("menu.counsellingService.question")} {index + 1}
      </Typography.Text>
      <div className="border border-solid border-[#d9d9d9] py-4 px-6 mt-2">
        <Controller
          name={`${controlName}.title`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              label="Question"
              required
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Input
                {...field}
                disabled={disable}
                placeholder={t("menu.enterHere")}
              />
            </Form.Item>
          )}
        />

        <Controller
          name={`${controlName}.question_type`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Form.Item
              label="Type"
              required
              validateStatus={error ? "error" : ""}
              help={error?.message}
            >
              <Select
                {...field}
                disabled={disable}
                placeholder="Select question type"
              >
                <Select.Option value={QUESTION_TYPE.TEXT}>
                  {t("menu.counsellingService.questionType.text")}
                </Select.Option>
                <Select.Option value={QUESTION_TYPE.MULTIPLE_CHOICE}>
                  {t("menu.counsellingService.questionType.multipleChoice")}
                </Select.Option>
                <Select.Option value={QUESTION_TYPE.CHECKBOXES}>
                  {t("menu.counsellingService.questionType.checkboxes")}
                </Select.Option>
                <Select.Option value={QUESTION_TYPE.DROPDOWN}>
                  {t("menu.counsellingService.questionType.dropdown")}
                </Select.Option>
                <Select.Option value={QUESTION_TYPE.DATE}>
                  {t("menu.counsellingService.questionType.date")}
                </Select.Option>
                <Select.Option value={QUESTION_TYPE.RATE}>
                  {t("menu.counsellingService.questionType.rate")}
                </Select.Option>
              </Select>
            </Form.Item>
          )}
        />

        {shouldRenderQuestionOptions && (
          <Form.Item label="Options" required className="mb-4">
            <Controller
              name={`${controlName}.options`}
              control={control}
              render={() => (
                <QuestionType
                  disable={disable}
                  form={form}
                  questionType={questionType}
                  questionIndex={index}
                />
              )}
            />
          </Form.Item>
        )}
      </div>
    </>
  );
};

export default Question;
