import { FunnelPlotOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListHeader, Stack } from "@/components";
import { cn } from "@/lib/utils";
import { SupportRequest } from "@/types/Api";

import { useListServiceRequestFilter } from "../-data/hooks";

const Style = () => {
  return (
    <style>
      {`
        .ant-dropdown-menu-item-group-list {
            margin: 0;
            .ant-dropdown-menu-item,.ant-dropdown-menu-item-only-child{
                padding: 5px 28px;
            }
        }
      `}
    </style>
  );
};

interface FilterType {
  status?: Exclude<SupportRequest["status"], undefined>[];
  search?: string;
}

interface Props {
  isLoading?: boolean;
}

const ServiceRequestListHeader = ({ isLoading = false }: Props) => {
  const { t } = useTranslation("dashboard");
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<FilterType>({
    status: undefined,
    search: "",
  });
  const { value: serviceRequestListState, set: setServiceRequestListState } =
    useListServiceRequestFilter();

  const handleSearch = (search: string) => {
    setServiceRequestListState((prev) => {
      return {
        ...prev,
        page: 1,
        search,
      };
    });
  };

  const handleChangeCheckbox = (
    currentStatus: Exclude<SupportRequest["status"], undefined>,
    checked: boolean,
  ) => {
    setFilter((prev) => {
      const { status = [] } = prev;
      const statusSet = new Set(status);
      checked ? statusSet.add(currentStatus) : statusSet.delete(currentStatus);

      return {
        ...prev,
        status: Array.from(statusSet),
      };
    });
  };

  const handleResetFilter = () => {
    setServiceRequestListState((prev) => {
      return {
        ...prev,
        page: 1,
        status: undefined,
        search: "",
      };
    });
    setFilter({
      status: undefined,
    });
    setOpen(false);
  };

  const handleApplyFilter = () => {
    setServiceRequestListState((prev) => {
      const { status: _, ...rest } = prev;
      return filter.status
        ? { ...rest, status: filter.status, page: 1 }
        : { ...rest };
    });
    setOpen(false);
  };

  const shouldDisableResetBtn =
    !serviceRequestListState.status ||
    serviceRequestListState.status.length === 0;

  return (
    <ListHeader
      title={t("menu.list")}
      onChange={handleSearch}
      search={serviceRequestListState.search}
      filter={
        <>
          <Style />
          <Dropdown
            open={open}
            onOpenChange={setOpen}
            trigger={["click"]}
            menu={{
              className: "w-[173px]",
              selectable: true,
              selectedKeys:
                (filter.status?.length ?? 0) > 0
                  ? (filter.status as string[])
                  : [],
              items: [
                {
                  label: (
                    <Stack className="justify-between gap-6">
                      <Button
                        size="small"
                        type="text"
                        onClick={handleResetFilter}
                        disabled={shouldDisableResetBtn}
                        className={cn(
                          !shouldDisableResetBtn &&
                            "text-[var(--ant-primary-color)]",
                        )}
                      >
                        {t("menu.button.reset")}
                      </Button>
                      <Button
                        size="small"
                        type="text"
                        className="bg-brand-primary text-white"
                        onClick={handleApplyFilter}
                        loading={isLoading}
                      >
                        {t("menu.button.ok")}
                      </Button>
                    </Stack>
                  ),
                  className: cn("hover:bg-transparent cursor-default px-2"),
                  key: "action",
                },
                { type: "divider" },
                {
                  label: (
                    <Typography.Text className="font-medium">
                      {t("menu.serviceRequest.requestStatus")}
                    </Typography.Text>
                  ),
                  type: "group",
                  className: "mx-0",
                  children: [
                    {
                      key: "PROCESS",
                      label: (
                        <Checkbox
                          onChange={(e) => {
                            handleChangeCheckbox(
                              "In-Progress",
                              e.target.checked,
                            );
                          }}
                          checked={filter.status
                            ?.join(",")
                            .includes("In-Progress")}
                        >
                          In Process
                        </Checkbox>
                      ),
                    },
                    {
                      key: "CLOSE",
                      label: (
                        <Checkbox
                          onChange={(e) => {
                            handleChangeCheckbox("Resolved", e.target.checked);
                          }}
                          checked={filter.status
                            ?.join(",")
                            .includes("Resolved")}
                        >
                          Resolved
                        </Checkbox>
                      ),
                    },
                  ],
                },
              ],
            }}
          >
            <Stack
              className="cursor-pointer gap-1"
              onClick={() => {
                setOpen(!open);
              }}
            >
              <span>{t("menu.filter")}</span>
              <FunnelPlotOutlined className="text-[#8C8C8C]" />
            </Stack>
          </Dropdown>
        </>
      }
    />
  );
};

export default ServiceRequestListHeader;
