import { queryOptions } from "@tanstack/react-query";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/vanilla/utils";

import { fetchApiClient } from "@/lib/utils/fetchUtils";

export const surveyQueries = {
  details: (id: string) =>
    queryOptions({
      queryKey: ["survey", id],
      queryFn: async () => {
        const { data } = await fetchApiClient.surveys.getDetailSurveyForm(id);
        return data;
      },
    }),
};

export const surveyAtoms = {
  details: atomFamily((id: string) =>
    atomWithSuspenseQuery(() => surveyQueries.details(id)),
  ),
};
